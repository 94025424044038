<template>
  <div class="body">
    <section class="two-pane" style="background: #f0f6fe">
      <div class="left-pane two-pane-vertical" style="flex: 1">
        <div class="image-container">
          <img :src="loinfo.lo_image" />
        </div>
        <div
          class="one-pane"
          style="line-height: 35px; padding: 0; width: 100%"
        >
          <h2>{{ loinfo.first_name }} {{ loinfo.last_name }}</h2>
          <p>
            {{ loinfo.title
            }}<span v-if="loinfo.nmls">, NMLS #{{ loinfo.nmls }}</span>
            <br /><strong>Phone: </strong>
            <a :href="'tel:' + loinfo.phone_number">{{
              loinfo.phone_number
            }}</a>
            <br /><strong>Email: </strong>
            <a :href="'mailto:' + loinfo.email">{{ loinfo.email }}</a>
          </p>
          <div @click="onButtonClick" style="width: 70%; margin: auto">
            <div
              v-if="loinfo.right_button_text"
              class="link-button right-button"
              :style="{ background: loinfo.custom_button_color || '#8cbeff' }"
              >
              <i class="fa-duotone fa-clipboard" style="padding: 10px"></i>
              <span>{{ loinfo.right_button_text }}</span>
            </div>
          </div>
          <div @click="onButtonClick" style="width: 70%; margin: auto">
            <div
              v-if="loinfo.left_button_text"
              class="link-button left-button"
              :style="{ background: loinfo.custom_button_color || '#8cbeff' }"
            >
              <i class="fa-duotone fa-calendar" style="padding: 10px"></i>
              <span>{{ loinfo.left_button_text }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="right-pane two-pane-vertical"
        style="
          flex: 2;
          border-radius: 3px;
          padding: 20px 10px;
        "
      >
        <div class="content-left scroller" style="padding-bottom: 0px;">
          <p>{{ loinfo.text_section_body_p1 }}</p>
          <p>{{ loinfo.text_section_body_p2 }}</p>
          <br />
        </div>
        <!--Kendo ScrollView-->
        <!-- <h1>Reviews</h1> -->
        <GSDivider></GSDivider>

        <ScrollView
          :style="{
            width: '100%',
            height: '500px',
            border: 'none',
            background: 'white',
            marginTop: '5px',
          }"
          :data-items="reviews"
          :content="'content'"
          :endless="true"
          :pageable="false"
          :automaticViewChangeInterval="10000"
          :automaticViewChange="true"
          :arrows="false"
          v-if="reviews.length"
        >
          <template v-slot:content="{ props }">
            <div class="two-pane-vertical">
              <div class="two-pane-horizontal" style="">
                <div class="content-left review" style="">
                  <div class="star-container" style="">
                    <span v-for="n in props.item.score" :key="n"
                      ><i class="fa-solid fa-star fa-xl"></i
                    ></span>
                    <span v-for="n in 5 - props.item.score" :key="n"
                      ><i class="fa-regular fa-star fa-xl"></i
                    ></span>
                  </div>
                  <div>
                    {{ props.item.borrower_name }}
                  </div>
                </div>
                <div class="content-right review" style="">
                  {{ props.item.display_date }}
                </div>
              </div>
              <div class="content-left review" style="font-size: 1.5rem">
                {{ props.item.message }}
              </div>
            </div>
          </template>
        </ScrollView>

        <!--  -->
      </div>
    </section>
  </div>
</template>

<script>
import { ScrollView } from "@progress/kendo-vue-scrollview";
import GSDivider from "./GSDivider.vue";
export default {
  emits: ["show"],
  components: {
    ScrollView,
    GSDivider,
  },
  name: "LoBody",
  props: {
    msg: String,
    loinfo: Array,
    reviews: Array,
  },
  data: function () {
    return {
      items: [
        {
          review: this.reviews[0],
        },
        {
          review: this.reviews[1],
        },
      ],
    };
  },
  methods: {
    onButtonClick(e) {
      var buttonText = e.target.innerHTML;
      if (buttonText === "Apply Now") {
        window.open(
          window.location.origin +
            "/apply?lar=" +
            (this.loinfo.app_pointer
              ? this.loinfo.app_pointer
              : this.loinfo.lar) +
            "&workflowid=" +
            this.loinfo.application_ID,
        );
      } else if (buttonText === "Schedule Appointment") {
        this.$emit("show");
      }
    },
  },
  mounted() {
    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/src/assets/css/LOBody.css";
</style>
