<template>

    <div style="width: 100%; margin-top: 100px;">
      <div class="contact-form">
        <div class="contact-form-header">
          <div class="contact-form-lo">
            <div class="contact-form-lo-image">
              <img :src="loinfo.lo_image" />
            </div>
            <div class="contact-form-lo-info">
              <h2>{{ loinfo.first_name }} {{ loinfo.last_name }}</h2>
              <h3>{{ loinfo.title }}</h3>
            </div>
          </div>
        </div>
        <form class="contact-form-form">
          <fieldset>
            <div class="field-container">
              <!-- Name and State -->
              <div class="form-name">
                <label for="name">Name</label>
                <input id="name" type="text" required="true" v-model="contactName"/>
              </div>
              <stateSelect @send="(n) => this.contactState = n"></stateSelect>
            </div>
            <div class="field-container">
              <!-- Email and Phone -->
              <div class="form-email">
                <label for="email">Email</label>
                <input id="email" type="email" required="true" v-model="contactEmail"/>
              </div>
              <div class="form-phone">
                <label for="phone">Phone</label>
                <input id="phone" type="tel" required="true" v-model="contactPhone"/>
              </div>
            </div>
            <div class="field-container">
              <!-- Message Body -->
              <div class="form-message">
                <label for="message">How may we assist you?</label>
                <textarea id="message" type="text" required="true" v-model="contactMessage"></textarea>
              </div>
            </div>
          </fieldset>
          <div class="submit-button" @click="contactSubmitHandler">Submit</div>
        </form>
      </div>
    </div>
</template>

<script>
import stateSelect from "../components/stateSelect.vue";

export default {
  emits: ["hide"],

  props: {
    visibleDialog: Boolean,
    loinfo: Array,
  },
  components: {
    stateSelect,
  },
  data: function () {
    return {
      contactName: "",
      contactEmail: "",
      contactState: "",
      contactPhone: "",
      contactMessage: ""
    };
  },
  methods: {
    contactSubmitHandler() {
      window.open('mailto:' + this.loinfo.email + '?subject=Contact Form&body='+ this.emailBody, '_blank');
      window.location.href =
          window.location.origin + "/?lar=" + this.loinfo.lar;
    },
    updateState(state) {
      this.contactState = state;
    }
  },
  computed: {
    emailBody() {
      return "Name: " + this.contactName + " %0D%0AState: " + this.contactState + " %0D%0AEmail: " + this.contactEmail + " %0D%0APhone: " + this.contactPhone + " %0D%0AMessage: " + this.contactMessage
    }
  }
};
</script>

<style scoped>
@import "/src/assets/css/LOHeader.css";
input {
  width: auto;
}
fieldset {
  align-items: center;
}
.field-container {
  margin: 0;
  flex-direction: row;
  width: 95%;
  justify-content: center;
}
@media (max-width: 425px){
  .field-container {
    flex-direction: column;
  }
}
</style>
