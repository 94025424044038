import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../Views/HomeView.vue";
import LoanProgram from "../Views/LoanProgram.vue";
import Calculators from "../Views/Calculators.vue";
import Apply from "../Views/Apply.vue";
import InfoLinks from "../Views/InfoLinks.vue";
import ContactForm from "../Views/ContactForm.vue";

// var lar = "jnewton";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/loanprograms",
    name: "loanprograms",
    component: LoanProgram,
  },
  {
    path: "/calculators",
    name: "calculators",
    component: Calculators,
  },
  {
    path: "/apply",
    name: "apply",
    component: Apply,
  },
  {
    path: "/info",
    name: "info",
    component: InfoLinks,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactForm
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
