<template>
  <div class="container">
    <p>2000-2017 Gold Star Mortgage Financial Group, Corporation</p>
    <p>Last Revised: March 12, 2014</p>
    <p><u>Terms of Use </u></p>
    <p>
      This Website, and any of the services provided by Gold Star Mortgage
      Financial Group, Corporation ("Gold Star" or "we") in connection with this
      Website, including any downloadable applications, mobile applications,
      programs, materials, etc. (collectively "the Technology") are being
      provided to you subject to these Terms of Use, which govern your use of
      the Technology.
    </p>
    <p>
      Please read these Terms of Use carefully. By visiting and/or using the
      Technology, including any of the services offered on this Website, you
      agree to be bound by these Terms of Use, whether or not you complete a
      transaction with Gold Star; and, whether or not you complete your
      transaction using the Technology or through the use of another medium,
      such as telephone, fax, email, postal mail, overnight delivery services,
      in person, or otherwise.
    </p>
    <p>
      By using the Technology you acknowledge that certain features thereof and
      products and/or services of Gold Star offered by or through the Technology
      may be subject to additional terms, conditions and disclaimers.
    </p>
    <p>
      If you do not agree to these Terms of Use, you are not authorized to use
      the Technology, or any of the products or services that Gold Star may
      offer through the Technology. We reserve the right to, in our sole
      discretion and without notice, deny you access and/or use of the
      Technology for any reason or no reason at all.
    </p>
    <p><u>Information Subject To Change </u></p>
    <p>
      The content of the Technology may be subject to change, and we make no
      guarantees that the information contained on the Technology is accurate,
      complete, or current. The information may contain typographical errors or
      technical inaccuracies, and while we endeavor to complete and correct all
      information in "live time" we may require additional time to change the
      information.
    </p>
    <p>
      The information and materials contained in these pages, and the terms,
      conditions and descriptions that appear on this site, are subject to
      change at any time without notice. We may change the products, services
      and other information identified on this site at any time. Applicable
      terms, conditions and eligibility requirements are subject to change and
      not all products and services are available in all geographic areas.
    </p>
    <p>
      The information published on this site is provided as a convenience to our
      visitors and is for informational purposes only. You should verify all
      information with Gold Star personnel before relying on it and any
      decisions which you may make based upon the information contained on this
      site shall be at your sole risk and responsibility. Your eligibility for
      particular products and services is, within the bounds of applicable law,
      subject to final approval, determination and acceptance by Gold Star in
      its sole and absolute discretion, notwithstanding your visit to and/or use
      of the Technology.
    </p>
    <p>
      The Technology may contain links to other sites on the Internet and such
      links are provided solely for the convenience of our visitors. We do not,
      unless otherwise expressly stated, endorse, control, approve or sponsor
      the linked sites or advertisements contained thereon.
    </p>
    <p>
      WE EXPRESSLY DISCLAIM ANY LIABILITY FOR THE CONTENT OF INFORMAITON ON THE
      WEBSITES OR TECHNOLOGY APPLICATIONS OF ANY OTHER COMPANY.
    </p>
    <p>
      Products or services available via a linked site or technology application
      are subject to the Terms of those sites/applications, and you must read
      and adhere to those Terms accordingly. Any advice, opinions,
      recommendations, statements, or other communications contained on a linked
      site or technology application are those of the providers of said
      technology, and not of Gold Star. <br />
      &nbsp;<u></u><br />
      <u>Applications</u>
    </p>
    <p>
      If you are using the Technology to apply for a product or service that
      Gold Star offers, you agree that the information contained in your
      application will be truthful, accurate, complete, and current, as Gold
      Star will rely on this information to process your application. You
      further agree that should any information change, you will promptly notify
      Gold Star. Providing incomplete or inaccurate information may form the
      basis for the denial of an application at any time, and may be
      unlawful.&nbsp;
    </p>
    <p><u>Disclaimer of Warranties </u></p>
    <p>
      &nbsp;THE INFORMATION AND MATERIALS CONTAINED IN THIS SITE, INCLUDING
      TEXT, GRAPHICS, LINKS OR OTHER ITEMS - ARE PROVIDED "AS-IS" AND "AS
      AVAILABLE" AND WE DO NOT WARRANT THE ACCURACY, ADEQUACY OR COMPLETENESS OF
      SUCH INFORMATION AND MATERIALS AND EXPRESSLY DISCLAIM LIABILITY FOR ERRORS
      OR OMISSIONS IN THIS INFORMATION AND MATERIALS. NO WARRANTY OF ANY KIND,
      IMPLIED, EXPRESS OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE
      WARRANTIES OF NON-INFRINGEMENT OF THIRD PARTY RIGHTS, TITLE,
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND FREEDOM FROM
      COMPUTER VIRUS, OR OTHER MALWARE, IS GIVEN IN CONJUNCTION WITH SUCH
      INFORMATION AND MATERIALS.
    </p>
    <p>
      &nbsp;YOU ACKNOWLEDGE THAT NEITHER GOLD STAR, ITS AFFILIATES NOR ANY OF
      THEIR RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR
      LICENSORS WARRANT THAT THE SERVICES OR THE SITE WILL BE UNINTERRUPTED OR
      ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
      OBTAINED FROM USE OF THE SERVICES OR THE SITE, OR AS TO THE TIMELINESS,
      SEQUENCE, ACCURACY, RELIABILITY, COMPLETENESS OR CONTENT OF ANY
      INFORMATION, SERVICE, OR MERCHANDISE PROVIDED THROUGH THE SERVICES AND THE
      SITE. GOLD STAR DOES NOT ENDORSE PRODUCTS OR SERVICES APPEARING ON LINKED
      SITES OR PURCHASED VIA LINKED SITES.
    </p>
    <p>
      &nbsp;EXCEPT AS SPECIFICALLY PROVIDED IN THIS AGREEMENT OR WHERE THE LAW
      REQUIRES A DIFFERENT STANDARD, YOU AGREE THAT GOLD STAR IS NOT RESPONSIBLE
      FOR ANY LOSS, PROPERTY DAMAGE OR BODILY INJURY, WHETHER CAUSED BY ACCESS
      TO OR USE OF THE SERVICES OR THE SITE. TO THE MAXIMUM EXTENT PERMISSIBLE
      UNDER APPLICABLE LAW, GOLD STAR WILL NOT BE RESPONSIBLE TO YOU OR ANY
      THIRD PARTY CLAIMING THROUGH YOU FOR ANY DIRECT, INDIRECT, SPECIAL OR
      CONSEQUENTIAL, ECONOMIC OR OTHER DAMAGES ARISING IN ANY WAY OUT OF THE
      INSTALLATION OR USE OF THE SERVICES, THE SITE, ANY ON-LINE SERVICES OR
      INTERNET BROWSER SOFTWARE, INCLUDING LIABILITY ASSOCIATED WITH ANY
      COMPUTER VIRUSES WHICH MAY INFECT YOUR COMPUTER SYSTEM.
    </p>
    <p><u>Limitation of Liability </u></p>
    <p>
      IN NO EVENT WILL GOLD STAR BE LIABLE FOR ANY DAMAGES, INCLUDING, WITHOUT
      LIMITATION: DIRECT OR INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL
      DAMAGES, LOSSES OR EXPENSES ARISING IN CONNECTION WITH THE TECHNOLOGY OR
      USE THEREOF OR INABILITY TO USE BY ANY PARTY, OR IN CONNECTION WITH ANY
      FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN
      OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINK OR SYSTEM FAILURE, EVEN
      IF GOLD STAR, OR REPRESENTATIVES THEREOF ARE ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, LOSSES OR EXPENSES. HYPERLINKS TO OTHER INTERNET RESOURCES
      ARE AT YOUR OWN RISK; THE CONTENT, ACCURACY, OPINIONS EXPRESSED, AND OTHER
      LINKS PROVIDED BY THESE RESOURCES ARE NOT INVESTIGATED, VERIFIED,
      MONITORED OR ENDORSED BY GOLD STAR.
    </p>
    <p><u>Indemnification </u></p>
    <p>
      You agree to defend, indemnify and hold harmless Gold Star, its
      affiliates, vendors and all directors, officers, employees and agents of
      Gold Star and its affiliates and vendors from and against any claim,
      demand, damages, liabilities, suit, expenses, costs or proceeding,
      including attorney's fees and court costs, that relates to or is based on
      your violation of these Terms of Use or misuse of the Technology, or any
      services available from Gold Star through the Technology, including such
      violation or misuses conducted by your employee or agent, if applicable.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;<u>No Unlawful or Prohibited Use </u></p>
    <p>
      &nbsp;As a condition of your use of the Technology, you agree and warrant
      to Gold Star that you will not use or access the Technology for any
      purpose that is unlawful or prohibited by these terms, conditions, and
      notices. If your use or access violates any law or any of these terms or
      conditions, your permission to use the Technology shall terminate
      automatically. &nbsp;Without limitation, the following is a non-exhaustive
      list of prohibited uses of the Technology:
    </p>
    <ol>
      <li>
        To interfere with or induce a breach of a contractual relationship
        between Gold Star and its employees, clients, or business partners;
      </li>
      <li>
        To use in such a way that is unlawful, prohibited, harmful, or
        destructive to a person or property;
      </li>
      <li>
        To use in such a way that transmits advertisements, spam, schemes,
        floording, DDOS attacks, malware, viruses, or unsolicited
        communications;
      </li>
      <li>
        To use in such a way that email addresses are harvested, phished,
        pharmed, or otherwise compromised;
      </li>
      <li>
        To use in such a way that Gold Star's network or technology is
        interfered with;
      </li>
      <li>
        To transmit material that is obscene, violent, discriminatory, hateful,
        harassing, threatening, sexually explicit, lewd, offensive, harmful,
        pornographic, fraudulent, misleading, misrepresentative, manipulative,
        or that unlawfully impersonates or otherwise misrepresents your
        affiliation with any entity;
      </li>
      <li>
        That harms minors in any way, including but not limited to transmitting
        or uploading content that violates child pornography laws, child sexual
        exploitation laws, and laws prohibiting the depiction of minors engaged
        in sexual conduct;
      </li>
      <li>
        To use the Technology in such a way that it discriminates on a person on
        the basis of race, color, creed, sex, religion, familial status,
        handicap, or any other protected class contemplated by the industries in
        which Gold Star operates;
      </li>
      <li>
        To use the Technology in a way that incites the commission of a crime or
        unlawful activities, including the conspiracy to commit such crimes;
      </li>
      <li>
        To use the Technology to unlawfully transmit any confidential,
        proprietary, or other intellectual property information that would
        infringe on the rights of a third party;
      </li>
    </ol>
    <p>&nbsp;</p>
    <p><u>Website Security </u></p>
    <p>
      You are prohibited from violating or attempting to violate the security of
      the Technology, including, without limitation: (a) accessing data not
      intended for your viewing or logging into a server or account which you
      are not authorized to access; (b) attempting to probe, scan or test the
      vulnerability of a system or network or to breach security or
      authentication measures without proper authorization; or (c) attempting to
      interfere with service to any user, host or network. Violations of system
      or network security may result in civil or criminal liability. Gold Star
      will investigate occurrences that may involve such violations and may
      involve, and cooperate with, law enforcement authorities in prosecuting
      users who are involved in such violations.
    </p>
    <p>
      Certain features of the Technology may be restricted to users who have
      obtained a user identification and password. Please be sure to protect and
      maintain confidentiality of passwords in connection with your use of the
      Technology. You agree to notify us immediately if you believe your User
      ID, password, or other personally identifiable information has been lost,
      stolen, or compromised. &nbsp;You also acknowledge and agree that you are
      solely responsible for all damages or claims that may arise from any
      access to or use of this website by any person to whom you have provided
      your user ID or password.
    </p>
    <p><u>Copyrights and Trademarks </u></p>
    <p>
      Copyright in the pages and in the screens displaying the pages, and in the
      information and material therein and their arrangement is owned/licensed
      by Gold Star, unless otherwise indicated, and are protected by U.S.
      copyright laws and international treaties. The copyrighted materials on
      the Technology include, but are not limited to, the text, design,
      software, images, graphics, source code, and the content on the
      Technology. You are authorized to view the information available on the
      Technology for your informational purposes only. You may not copy,
      republish, redistribute, transmit, participate in the transmission of,
      create derivatives of, alter, edit, reverse engineer or attempt to do any
      of the foregoing, or exploit in any manner any material including by
      storage on retrieval systems, without the express written permission of
      Gold Star.
    </p>
    <p>
      Gold Star Mortgage Financial Group is a Common Law trademark or trade
      name, owned by Gold Star. You may not use, copy, display, distribute,
      modify or reproduce any of the trademarks found on the Technology except
      as authorized in this paragraph.
    </p>
    <p>
      Gold Star is committed to protecting copyrights and expects the same of
      visitors of this Website and users of the Technology. The Digital
      Millennium Copyright Act provides recourse for owners of copyrights who
      believe that material appearing on the internet infringes their rights
      under copyright law. If you believe in good faith that any material used
      or displayed on the Technology infringes your copyrights, you may send us
      a notice requesting the information be removed.&nbsp;
    </p>
    <p>If you so believe, please provide the following:</p>
    <ol>
      <li>
        A signature verifying that you are, or are authorized to act on behalf
        of, the owner of an intellectual property right that is being infringed;
      </li>
      <li>
        Identification of the alleged copyrighted work, including the locations
        in which they appear on the Technology;
      </li>
      <li>Contact information allow us to contact the complaining party;</li>
      <li>
        A statement that the claim is made in good faith, and that under penalty
        of perjury, you are authorized to make such a claim.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Information should be provided to
      <a href="mailto:help@goldstarfinancial.com">help@goldstarfinancial.com</a>
      or 100 Phoenix Drive, Suite 300, Ann Arbor, MI 48108 ATTN: Legal Dept.
    </p>
    <p><u>Email &amp; Online Information Requests </u></p>
    <p>
      Please ensure the security of all email messages that you send to Gold
      Star. Note that information contained in electronic communications may be
      intercepted in transit, and Gold Star cannot control access to information
      outside of Gold Star's servers. We recommend that you do not send any
      confidential information to Gold Star via email unless it is encrypted and
      password protected. Passwords should never be sent in plaintext or via
      email. If you choose to send confidential information to Gold Star via
      email, you accept all risk that such information may be intercepted by a
      third party.
    </p>
    <p>
      By submitting information to Gold Star through the Technology, you are
      inquiring about products and services that Gold Star may offer, and you
      are giving Gold Star permission to contact you via email, telephone,
      letter, fax, or other medium using the information you provide, regardless
      of registration with any state or federal Do-Not-Call list. <br />
      &nbsp;<br />
      <u>Communications</u>
    </p>
    <p>
      By submitting any comment, complaint, suggestion, improvement, idea,
      testimonial or other communication ("Submission" or collectively
      "Submissions") through the Technology or any other method, you acknowledge
      and agree that any and all Submissions, and all rights therein, become the
      sole and exclusive property of Gold Star, including any future rights,
      regardless of any subsequent modification or deletion of the provisions in
      the Terms of Use. You acknowledge and agree that you irrevocably disclaim
      any right in the Submission and that the right to use, publish, display,
      commercially exploit, and create derivative ideas based on the Submission
      belong exclusively to Gold Star with no compensation, notice, credit or
      other obligation being due or owed to you or anyone else. We reserve the
      right to record and monitor any and all communications. By communicating
      with Gold Star through any means, you consent to such communications being
      recorded and monitored.
    </p>
    <p><u>General </u></p>
    <p>
      We reserve the right to modify these Terms of Use at any time without
      notice. By continuing to use of the Technology and the services you agree
      to be bound by the rules contained in the most recent version of this
      Agreement.
    </p>
    <p>&nbsp;<u>Governing Law </u></p>
    <p>
      You agree that these Terms of Use shall be governed by and construed in
      accordance with the laws of the State of Michigan, without giving effect
      to any principles of conflicts of law. You agree that any action at law or
      in equity arising out of or relating to these Terms of Use or the use of
      this website shall be filed only in the state or federal courts located in
      Washtenaw County, Michigan, and you hereby consent and submit to the
      personal jurisdiction of such courts for the purposes of litigating any
      such action, regardless of where you may be physically located or where
      you may have accessed the Technology governed by the Terms of Use.
    </p>
    <p><u>Severability</u></p>
    <p>
      You agree that if any provision of these Terms of Use shall be found to be
      unlawful or void, or for any reason unenforceable, then that provision
      shall be deemed severable from the other provisions of these Terms of Use
      and shall not affect the validity and enforceability of such other
      provisions.
    </p>
    <p><u>Contact and Violations </u></p>
    <p>
      Please contact us with any questions regarding these Terms of Use. Please
      report any violations of the Terms of Use to
      <a href="mailto:help@goldstarfinancial.com">help@goldstarfinancial.com</a>
    </p>
  </div>
</template>

