<template>
  <div class="header" style="background-color: white; justify-content: space-between;">
    <a :href="loinfo.header_link">
      <img :src="loinfo.main_logo" style="padding-left: 5px" />
    </a>
    <div style="display: flex; flex-direction: row; height: 100%; justify-content: center; align-items: center;">
      <div class="two-pane-vertical lo-contact">
        <div class="" style="padding: 5px;">
          <strong>{{ loinfo.first_name }} {{ loinfo.last_name }}</strong>
        </div>
        <div>
          {{ loinfo.phone_number }}
        </div>
      </div>
      <img class="header-img" :src="loinfo.app_header_image ? loinfo.app_header_image : loinfo.lo_image" />
    </div>
  </div>

  <div
    id="widget"
    class="em-widget"
    data-width="100%"
    data-height="800px"
    data-min-height="800px"
    data-site-id="1297790669"
    data-name="ecc-loan-app"
    :data-config=appworkflow
    data-aria-label="My Loan Application"
  ></div>
  <LOFooter :loinfo="loinfo"></LOFooter>
</template>

<script>
import apiService from "../api/api.service.js";
//import navMenu from "../components/navMenu.vue";
import LOFooter from "../components/LOFooter.vue";

export default {
  data: function () {
    return {
      contactFormDialog: false,
      loinfo: [],
      larFromQuery: "",
      appworkflow:''
    };
  },
  name: "ApplyView",
  props: {
    msg: String,
  },
  components: {
    LOFooter,
  },
  methods: {
    getLOInfo() {
      return apiService
        .GetLOPage(this.larFromQuery)
        .then((res) => {
          this.loinfo = res.data.data[0];
          document.title = "Mortgage Application";

          this.getReviews();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.larFromQuery = params.lar;
    this.appworkflow = '{"workFlowId":"' + params.workflowid + '","externalLoginWindow":"true"}'; 


  },
  mounted() {
    this.getLOInfo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/src/assets/css/homeView.css";
.em-widget{
  margin-top: 60px;
}
.header-img-container{
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  
}
.header-img{
  border-radius: 3px;
  object-fit: cover;
  padding-right: 15px;
  justify-content: center;
  align-items: center;
}
.lo-contact{
  margin: 20px 10px;
  font-size: 1rem;

}
@media (max-width: 782px){
  .lo-contact {
    font-size: .7rem;
  }
}

@media (max-width: 735px){
  .header{
    padding: 0px;
  }
  .header-img{
    display: none;
  }
}
@media (max-width: 524px){
  .em-widget{
    margin-top: 90px;
  }
}

@media (max-width: 500px){
  .lo-contact{
    width: 90px;
  }
  .em-widget{
    margin-top: 110px;
  }
}
@media (max-width: 450px){
  body{
    margin-top: 125px !important;
  }


}
</style>
