<template>
  <div class="header">
    <div class="left-side">
      <a :href="loinfo.header_link" class="imgContainer">
        <img :src="loinfo.main_logo" />
      </a>
    </div>
    <div class="right-side">
      <kendo-menu
        :items="loinfo.menus?.headMenu"
        :open-on-click="true"
        className="menu"
        @select="onMenuSelect"
      >
        <template v-slot:itemRender="{ props }">
          <div>
            {{ props.text }}
          </div>
        </template>
      </kendo-menu>

      <button class="nav-menu-button nav-menu-lg" @click="toggleNavDiv">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </div>
  <div id="navDiv" class="nav-menu nav-menu-lg">
    <kendo-menu
      id="vMenu"
      :items="loinfo.menus?.navMenu"
      :open-on-click="true"
      :vertical="true"
      className="v-menu"
      :item-render="itemRender"
      @select="onMenuSelect"
    >
      <template v-slot:itemRender="{ props }">
        <div>
          {{ props.item.text }}
        </div>
      </template>
    </kendo-menu>
  </div>
</template>

<script>
import { Menu } from "@progress/kendo-vue-layout";
import "@progress/kendo-theme-default/dist/all.css";

export default {
  emits: ["show", "lock"],
  components: {
    "kendo-menu": Menu,
  },
  props: {
    loinfo: Array,
  },
  data: function () {
    return {};
  },
  methods: {
    togglecontactform() {
      this.$emit("show");
    },
    onMenuSelect(e) {
      const linkText = e.item.text;
      // const navDiv = document.getElementById("navDiv");
      if (linkText === "Contact") {
        window.open(
          window.location.href =
          window.location.origin +
          "/contact?lar=" +
          this.loinfo.lar

        )
      } else if (linkText === "Make a Payment") {
        window.open(
          "https://connectgs.com/paymentlanding/universalportal",
          "_blank",
          "location=yes,height=700,width=520,scrollbars=yes,status=yes",
        );
      } else if (linkText === "Apply") {
        window.open(
          "https://lo-sites.goldstarfinancial.com/apply?lar=" +
            (this.loinfo.app_pointer
              ? this.loinfo.app_pointer
              : this.loinfo.lar) +
            "&workflowid=" +
            this.loinfo.application_ID,
        );
      } else if (linkText === "My Account") {
        window.open("https://loanlaunch.com");
      } else if (linkText === "FHA Loans") {
        window.location.href =
          window.location.origin +
          "/loanprograms?lar=" +
          this.loinfo.lar +
          "&programs=FHA";
      } else if (linkText === "Conventional Mortgages") {
        window.location.href =
          window.location.origin +
          "/loanprograms?lar=" +
          this.loinfo.lar +
          "&programs=ConventionalLoan";
      } else if (linkText === "VA Loans") {
        window.location.href =
          window.location.origin +
          "/loanprograms?lar=" +
          this.loinfo.lar +
          "&programs=VALoan";
      } else if (linkText === "Refinance Loans") {
        window.location.href =
          window.location.origin +
          "/loanprograms?lar=" +
          this.loinfo.lar +
          "&programs=RefinanceLoan";
      } else if (linkText === "Jumbo Financing") {
        window.location.href =
          window.location.origin +
          "/loanprograms?lar=" +
          this.loinfo.lar +
          "&programs=JumboFinancing";
      } else if (linkText === "Reverse Mortgages") {
        window.location.href =
          window.location.origin +
          "/loanprograms?lar=" +
          this.loinfo.lar +
          "&programs=ReverseLoan";
      } else if (linkText === "Condo Financing") {
        window.location.href =
          window.location.origin +
          "/loanprograms?lar=" +
          this.loinfo.lar +
          "&programs=CondoFinancing";
      } else if (linkText === "DACA Residents") {
        window.location.href =
          window.location.origin +
          "/loanprograms?lar=" +
          this.loinfo.lar +
          "&programs=DACA";
      } else if (linkText === "Down Payment Assistance") {
        window.location.href =
          window.location.origin +
          "/loanprograms?lar=" +
          this.loinfo.lar +
          "&programs=DownPaymentAssistance";
      } else if (linkText === "Calculators") {
        window.location.href =
          window.location.origin + "/calculators?lar=" + this.loinfo.lar;
      }
    },
    toggleNavDiv() {
      const menuText = document.getElementById("vMenu");
      const navDiv = document.getElementById("navDiv");
      if (navDiv.classList.contains("visible")) {
        menuText.classList.remove("visible");
        navDiv.classList.remove("visible");
        this.$emit("lock");
      } else {
        navDiv.classList.add("visible");
        menuText.classList.add("visible");
        this.$emit("lock");
      }
    },
  },
  mounted() {},
};
</script>

<style>
@import "/src/assets/css/LOHeader.css";
</style>
