<template>
  <div>
    <LOHeader
      @show="showContactFormDialog"
      @lock="lockScroll"
      :loinfo="loinfo"
    ></LOHeader>
    <div class="body">
      <section class="two-pane" style="background: #f0f6fe">
        <div
          class="right-pane two-pane-vertical"
          style="
            flex: 2;
            margin-top: 40px;
            border-radius: 3px;
            padding: 20px 10px;
          "
        >
          <div style="height: auto; margin-top: 100px">
            <iframe
              title="Iframewidgetcode"
              src="https://www.primcomortgage.com/calculator/iframe-widget?HighchartColumnInterest=262526&HighchartColumnPrincipal=85a3b3&HighchartPieInterest=262526&HighchartPiePrincipal=85a3b3&HighchartPieInsurance=658693&HighchartPieMortgageInsurance=dddad7&ButtonColor=85a3b3&HighlightTextColor=85a3b3&HighlightTextColorLight=85a3b3&HeaderColor=85a3b3&NavigateMode=true&CalcMode=Mortgage&Footnote=true&AmortTab=true&lo=false&lo-name=&lo-title=&lo-nmls=&lo-location=, &lo-email=&lo-phone=&lo-imageurl=&lo-applynow=&lo-mysite=&lo-logo="
              width="100%"
              height="1000px"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </section>
    </div>

    <LOFooter :loinfo="loinfo" style="bottom: 0"></LOFooter>

    <contactForm
      :visibleDialog="contactFormDialog"
      @hide="hideContactFormDialog"
      :loinfo="loinfo"
    ></contactForm>
  </div>
</template>

<script>
import LOHeader from "../components/LOHeader.vue";
import LOFooter from "../components/LOFooter.vue";
import contactForm from "../components/contactForm.vue";
import apiService from "../api/api.service.js";

export default {
  data: function () {
    return {
      contactFormDialog: false,
      loinfo: [],
      reviews: [],
      larFromQuery: "",
    };
  },
  name: "HomeView",
  props: {
    msg: String,
  },
  components: {
    LOHeader,
    LOFooter,
    contactForm,
  },
  methods: {
    onButtonClick(e) {
      var buttonText = e.target.innerHTML;
      if (buttonText === "Apply Now") {
        window.open(
          "https://lo-sites.goldstarfinancial.com/application.html?lar=" +
            this.loinfo.lar +
            "&workflowid=" +
            this.loinfo.application_ID,
        );
      } else if (buttonText === "Schedule Appointment") {
        this.$emit("show");
      }
    },
    showContactFormDialog() {
      this.contactFormDialog = true;
      document.body.classList.add("locked");
    },
    hideContactFormDialog() {
      this.contactFormDialog = false;
      document.body.classList.remove("locked");
    },
    lockScroll() {
      if (document.body.classList.contains("locked")) {
        document.body.classList.remove("locked");
      } else {
        document.body.classList.add("locked");
      }
    },
    getLOInfo() {
      return apiService
        .GetLOPage(this.larFromQuery)
        .then((res) => {
          this.loinfo = res.data.data[0];
          document.title =
            this.loinfo.first_name +
            " " +
            this.loinfo.last_name +
            " " +
            this.loinfo.nmls;

          this.getReviews();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReviews() {
      return apiService
        .GetReviews(this.loinfo.azure_ID)
        .then((res) => {
          this.reviews = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.larFromQuery = params.lar;
  },
  mounted() {
    this.getLOInfo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/src/assets/css/LOBody.css";
</style>
