<template>
    <div>
        <component :is="selectedPage" style="margin-top: 100px"></component>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
      infoPage: "",
    };
  },
  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.infoPage = params.infopage;
  },
  computed: {
    selectedPage() {
      return this.infoPage;
    },
  },
};
</script>

<style scoped>
@import "/src/assets/css/infoPage.css";

body {
  padding: 10px;
  display: flex;
  text-align: left;
  align-items: start;
}
</style>
