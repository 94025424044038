<template>
  <div>
    <div v-if="loaderVisible" id="loaderContainer" class="lo-loader-container">
      <loader :size="'large'" :type="'infinite-spinner'"></loader>
    </div>
    <!-- <HomeView></HomeView> -->
    <router-view />
  </div>
</template>

<script>
// import HomeView from "./Views/HomeView.vue";
import "@progress/kendo-theme-default/dist/all.css";
import { Loader } from "@progress/kendo-vue-indicators";

export default {
  data: function () {
    return {
      loaderVisible: true,
    };
  },
  name: "App",
  components: {
    // HomeView,
    loader: Loader,
  },
  mounted() {
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.k-loader {
  top: 50%;
}

.lo-loader-container {
  background-color: white;
  z-index: 9999;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.k-loader-segment {
  background-color: #062448;
}

.dissolve {
  transition-delay: 250ms;
  transition-duration: 600ms;
  opacity: 0;
  z-index: -1;
}
</style>
