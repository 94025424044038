<template>
  <div class="container">
    <h1>
      <u><strong>COMPANY STATE LICENSES</strong></u>
    </h1>
    <div class="state state--al">
      <h2>ALABAMA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 21502</p>
      <p>Regulatory Agency: AL State Banking Department</p>
      <p>Regulator Address: P.O. Box 4600</p>
      <p>Montgomery, AL 36103-4600</p>
      <p>
        Phone:
        <a
          href="tel:334-242-3452"
          aria-label="Alabama Regulatory Agency Contact Number 334-242-3452."
        >
          (334) 242-3452
        </a>
      </p>
      <p>
        Web Site:
        <u
          ><a href="https://www.banking.alabama.gov/">
            https://www.banking.alabama.gov/
          </a></u
        >
      </p>
    </div>
    <div class="state state--az">
      <h2>ARIZONA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>-Zona Leanding Team</i></strong>
      </p>
      <p>License Number: 0930764,1000239</p>
      <p>
        Regulatory Agency: Arizona Department of Insurance and Financial
        Institutions
      </p>
      <p>Regulator Address: 100 N. 15th Avenue, Suite 261, Phoenix, AZ 85007</p>
      <p>
        AZ Location: 16810 Ave. of the Fountains Suite 221, Fountain Hills, AZ
        85268
      </p>
      <p>
        Phone:
        <a
          href="tel:602-771-2800"
          aria-label="Arizona Regulatory Agency Contact Number 602-771-2800."
          >(602) 771-2800</a
        >
      </p>
      <p>Fax: (602) 381-1225</p>
      <p>
        Web Site:
        <u><a href="https://difi.az.gov/">https://difi.az.gov/</a></u>
      </p>
    </div>
    <div class="state state--ark">
      <h2>ARKANSAS</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 128932</p>
      <p>Regulatory Agency: Arizona Department of Financial Institutions</p>
      <p>Regulator Address: 1 Commerce Way, Suite 402, Little Rock, AR 72202</p>
      <p>
        Phone:
        <a
          href="tel:501-324-8688"
          aria-label="Arkansas Securities Department Contact Number 501-324-8688."
          >(501) 324-8688</a
        >
      </p>
      <p>
        Web Site:
        <u
          ><a href="https://www.securities.arkansas.gov/"
            >https://www.securities.arkansas.gov/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--ca">
      <h2>CALIFORNIA</h2>
      <div class="section">
        <p>License Number: 603B542</p>
        <p>Approved Names:</p>
        <p>
          <strong
            ><i>-Gold Star Mortgage Financial Group, Corporation</i></strong
          >
        </p>
        <p>
          <strong><i>-National Home Lending</i></strong>
        </p>
        <p>
          Regulatory Agency: California Department of Financial Protection and
          Innovation
        </p>
        <p>Regulator Address: 2101 Arena Blvd,</p>
        <p>Sacramento, CA 95834</p>
        <p>
          Phone:
          <a
            href="tel:916-445-7205"
            aria-label="California Regulatory Agency Contact Number 916-445-7205."
          >
            (916) 445-7205
          </a>
        </p>
        <p>Fax: (866) 275-2677</p>
        <p>
          Web Site:
          <u><a href="https://dfpi.ca.gov">https://dfpi.ca.gov</a></u>
        </p>
        <p>
          Licensed by the Department of Financial Protection and Innovation
          under the California Residential Mortgage Lending Act license
          #603B542.
        </p>
      </div>
      <div class="section">
        <p>License Number: 41DBO-115628</p>
        <p>Approved Names:</p>
        <p>
          <strong
            ><i>-Gold Star Mortgage Financial Group, Corporation</i></strong
          >
        </p>
        <p>
          Regulatory Agency: California Department of Financial Protection and
          Innovation
        </p>
        <p>Regulator Address: 2101 Arena Blvd</p>
        <p>Sacramento, CA 95834</p>
        <p>
          Phone:
          <a
            href="tel:916-445-7205"
            aria-label="California Regulatory Agency Contact Number 916-445-7205."
          >
            (916) 445-7205
          </a>
        </p>
        <p>Fax: (866) 275-2677</p>
        <p>
          Web Site:
          <u><a href="https://dfpi.ca.gov">https://dfpi.ca.gov</a></u>
        </p>
        <p>
          Licensed by the Department of Financial Protection and Innovation
          under the California Residential Mortgage Lending Act license
          #41DBO-115628.
        </p>
      </div>
      <div class="section">
        <p>License Number: 01889559</p>
        <p>Approved Names:</p>
        <p>
          <strong
            ><i>-Gold Star Mortgage Financial Group, Corporation</i></strong
          >
        </p>
        <p>Regulatory Agency: California Bureau of Real Estate</p>
        <p>Regulator Address: 1651 Exposition Blvd., Sacramento, CA 95815</p>
        <p>
          Phone:
          <a
            href="tel:877-373-4542"
            aria-label="California Bureau of Real Estate 877-373-4542."
          >
            (877) 373-4542
          </a>
        </p>
        <p>
          Web Site:
          <u><a href="https://www.dre.ca.gov/">https://www.dre.ca.gov/</a></u>
        </p>
      </div>
    </div>
    <div class="state state--co">
      <h2>COLORADO</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>
        Regulatory Agency: Department of Regulatory Agencies - Division of Real
        Estate
      </p>
      <p>Regulator Address: 1560 Broadway, Suite 925</p>
      <p>Denver, CO 80202</p>
      <p>
        Phone:
        <a
          href="tel:303-894-2166"
          aria-label="Department of Regulatory Agencies - Division of Real Estate Contact Number 303-894-2166."
        >
          (303) 894-2166
        </a>
      </p>
      <p>Fax: (303) 894-2683</p>
      <p>
        Web Site:
        <u
          ><a
            href="https://www.dora.state.co.us/real-estate/mortgagebrokerregistration.htm"
          >
            https://www.dora.state.co.us/real-estate/mortgagebrokerregistration.htm
          </a></u
        >
      </p>
    </div>
    <div class="state state--ct">
      <h2>CONNECTICUT</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: ML-3446</p>
      <p>
        Regulatory Agency: Connecticut Department of Banking-Consumer Credit
        Division
      </p>
      <p>Regulator Address: 260 Constitution Plaza</p>
      <p>Hartford, CT 06103-1800</p>
      <p>
        Phone:
        <a
          href="tel:860-240-8209"
          aria-label="Connecticut Department of Banking-Consumer Credit Division Contact Number 860-240-8209."
        >
          (860) 240-8209
        </a>
      </p>
      <p>Fax: (860) 240-8178</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.ct.gov/dob/cwp/view.asp?a=2231&amp;q=297818">
            https://www.ct.gov/dob/cwp/view.asp?a=2231&amp;q=297818
          </a></u
        >
      </p>
    </div>
    <div class="state state--columbia">
      <h2>DISTRICT OF COLUMBIA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: MLB3446</p>
      <p>
        Regulatory Agency: District of Columbia Department of Insurance,
        Securities, and Banking Bureau
      </p>
      <p>Regulator Address 810 First Street NE, Suite 701</p>
      <p>Washington, DC 20002</p>
      <p>
        Phone:
        <a
          href="tel:202-727-8000"
          aria-label="District of Columbia Department of Insurance, Securities, and
                  Banking Bureau Contact Number (202) 727-8000."
        >
          (202) 727-8000
        </a>
      </p>
      <p>Fax: (202) 535-1196</p>
      <p>
        Web Site:
        <u><a href="https://disb.dc.gov">https://disb.dc.gov</a></u>
      </p>
    </div>
    <div class="state state--fl">
      <h2>FLORIDA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>National Home Lending</i></strong>
      </p>
      <p>
        <strong><i>Reach Home Loans</i></strong>
      </p>
      <p>
        <strong><i>Certified Home Loans</i></strong>
      </p>
      <p>
        <strong><i>Legacy Mortgage Lending Group</i></strong>
      </p>
      <p>License Number: MLD203</p>
      <p>Regulatory Agency: Office of Financial Regulation</p>
      <p>Regulator Address: 200 East Gaines Street</p>
      <p>Tallahassee, FL 32399-0376</p>
      <p>
        Phone:
        <a
          href="tel:850-410-9895"
          aria-label="Office of Financial Regulation Contact Number 850-410-9895."
        >
          (850) 410-9895
        </a>
      </p>
      <p>Fax: (850) 410-9882</p>
      <p>
        Web Site:
        <u><a href="https://www.flofr.com/">https://www.flofr.com/</a></u>
      </p>
    </div>
    <div class="state state--id">
      <h2>IDAHO</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: MBL-2080003446</p>
      <p>Regulator Agency: ID Department of Finance</p>
      <p>Regulator Address: 800 Park Blvd., Suite 200</p>
      <p>Boise, ID 83712</p>
      <p>
        Phone:
        <a
          href="tel:(208) 332-8000"
          aria-label="Idaho Department of Finance Contact Number 208-332-8000."
        >
          (208) 332-8000
        </a>
      </p>
      <p>
        Web Site:
        <u
          ><a href="https://www.finance.idaho.gov/" class="text text-bolder"
            >https://www.finance.idaho.gov/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--il">
      <h2>ILLINOIS</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>Illinois Residential Mortgage Licensee</i></strong>
      </p>
      <p>License Number: MB.6760623</p>
      <p>
        For concerns or questions regarding properties located in Illinois,
        please contact: IL Department of Financial and Professional Regulation –
        Division of Banking
      </p>
      <p>Regulator Address: 100 W. Randolph Street, 9th Floor</p>
      <p>Chicago, IL 60601</p>
      <p>
        Phone:
        <a
          href="tel:(800) 532-8785"
          aria-label="IL Department of
               Financial and Professional Regulation Contact Number (800) 532-8785."
        >
          (800) 532-8785
        </a>
      </p>
      <p>TDD: (312) 793-0291</p>
      <p>
        Web Site:
        <u><a href="https://www.idfpr.com/">https://www.idfpr.com/</a></u>
      </p>
    </div>
    <div class="state state--in">
      <h2>INDIANA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 18314</p>
      <p>
        Regulatory Agency: Department of Financial Institutions – Consumer
        Credit Div.
      </p>
      <p>Regulator Address: 30 South Meridian Street, Suite 300</p>
      <p>Indianapolis, IN 46204</p>
      <p>
        Phone:
        <a
          href="tel:317-453-2539"
          aria-label="Department of Financial Institutions – Consumer Credit Div. Contact Number (317) 453-2539."
        >
          (317) 453-2539
        </a>
      </p>
      <p>
        Web Site:
        <u><a href="https://www.idfpr.com/">https://www.idfpr.com/</a></u>
      </p>
    </div>
    <div class="state state--ia">
      <h2>IOWA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 2015-0123</p>
      <p>Regulatory Agency: Iowa Division of Banking</p>
      <p>Regulator Address: 200 East Grand Ave.</p>
      <p>Des Moines, IA 50309</p>
      <p>
        Phone:
        <a
          href="tel:515-281-4014"
          aria-label="Iowa Division of Banking Contact Number (515) 281-4014."
        >
          (515) 281-4014
        </a>
      </p>
      <p>Fax: (515) 281-4862</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.idob.state.ia.us/"
            >https://www.idob.state.ia.us/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--ky">
      <h2>KENTUCKY</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: MC329022</p>
      <p>Regulatory Agency: Kentucky Department of Financial Institutions</p>
      <p>Regulator Address: 1025 Capital Center Drive, Suite 200</p>
      <p>Frankfort, KY 40601</p>
      <p>
        Phone:
        <a
          href="tel:800-223-2579"
          aria-label="Kentucky Department of Financial Institutions Contact Number (800) 223-2579."
        >
          (800) 223-2579
        </a>
      </p>
      <p>Fax: (502) 573-8787</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.kfi.ky.gov/Pages/default.aspx">
            https://www.kfi.ky.gov/Pages/default.aspx
          </a></u
        >
      </p>
    </div>
    <div class="state state--la">
      <h2>LOUISIANA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>Regulatory Agency: Louisiana Office of Financial Institutions</p>
      <p>Regulator Address: 8660 United Plaza Blvd.,</p>
      <p>Baton Rouge, LA 70809</p>
      <p>
        Phone:
        <a
          href="tel:225-925-4660"
          aria-label="Louisiana Office of Financial Institutions Contact Number 225-925-4660."
        >
          (225) 925-4660
        </a>
      </p>
      <p>
        Web Site:
        <u
          ><a href="http://www.ofi.state.la.us"
            >http://www.ofi.state.la.us</a
          ></u
        >
      </p>
    </div>
    <div class="state state--me">
      <h2>MAINE</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>Regulatory Agency: Bureau of Consumer Credit Protection</p>
      <p>Regulator Address: 35 State House Station</p>
      <p>Augusta, ME 04333</p>
      <p>
        Phone:
        <a
          href="tel:207-624-8527"
          aria-label="Maine Bureau of Consumer Credit Protection 207-624-8527."
        >
          (207) 624-8527
        </a>
      </p>
      <p>
        Web Site:
        <u
          ><a href="https://www.maine.gov/pfr/consumercredit/index.shtml"
            >https://www.maine.gov/pfr/consumercredit/index.shtml</a
          ></u
        >
      </p>
    </div>
    <div class="state state--md">
      <h2>MARYLAND</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 18622</p>
      <p>Regulatory Agency: Maryland Commissioner of Financial Regulation</p>
      <p>Regulator Address:500 N Calvert Street, Suite 402 Baltimore,</p>
      <p>Maryland 21202</p>
      <p>
        Phone:
        <a
          href="tel:410-230-6100"
          aria-label="Maryland Commissioner of Financial Regulation Contact Number 410-230-6100."
        >
          (410) 230-6100
        </a>
      </p>
      <p>Fax: (410) 333-0475</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.dllr.state.md.us/finance/"
            >https://www.dllr.state.md.us/finance/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--ma">
      <h2>MASSACHUSETTS</h2>
      <p>In Massachusetts, we arrange loans, but we do not make loans.</p>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: Broker MB3446</p>
      <p>License Type: Massachusetts Mortgage Broker</p>
      <p>Regulatory Agency: MA Division of of Banks</p>
      <p>Regulator Address:1000 Washington Street, 10th Floor,</p>
      <p>Boston, MA 02118</p>
      <p>
        Phone:
        <a
          href="tel:617-956-1500 ext. 351"
          aria-label="Maryland Commissioner of Financial Regulation Contact Number (617) 956-1500 ext. 351."
        >
          (617) 956-1500 ext. 351
        </a>
      </p>
      <p>Fax: (410) 333-0475</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.mass.gov/ocabr/government/oca-agencies/dob-lp/">
            https://www.mass.gov/ocabr/government/oca-agencies/dob-lp/
          </a></u
        >
      </p>
    </div>
    <div class="state state--mi">
      <h2>MICHIGAN</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>-National Home Lending</i></strong>
      </p>
      <p>
        <strong><i>-Legacy Mortgage Lending Group</i></strong>
      </p>
      <p>
        <strong><i>-Lakes Mortgage</i></strong>
      </p>
      <p>License Number: FL2984, SR1059</p>
      <p>
        Regulatory Agency: MI Department of Insurance and Financial Services
      </p>
      <p>Regulator Address:PO Box 30220</p>
      <p>Lansing, MI 48909-7720</p>
      <p>
        Phone:
        <a
          href="tel:877-999-6442"
          aria-label="MI Department of Insurance and Financial Services Contact Number (877) 999-6442"
        >
          (877) 999-6442
        </a>
      </p>
      <p>Fax: (517) 335-3157</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.michigan.gov/difs"
            >https://www.michigan.gov/difs</a
          ></u
        >
      </p>
    </div>
    <div class="state state--mn">
      <h2>MINNESOTA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: MN-MO-20544705</p>
      <p>Regulatory Agency: Department of Commerce-Banking &amp; Finance</p>
      <p>Regulator Address: 85 7th Place East, Suite 500</p>
      <p>St. Paul, MN 55101</p>
      <p>
        Phone:
        <a
          href="tel:651-539-1723"
          aria-label="Department of Commerce-Banking &amp; Finance Contact Number (651)539-1723."
        >
          (651)539-1723
        </a>
      </p>
      <p>Fax: (651) 296-8591</p>
      <p>
        Web Site:
        <u
          ><a
            href="https://mn.gov/commerce/banking-and-finance/topics/mortgage"
          >
            https://mn.gov/commerce/banking-and-finance/topics/mortgage
          </a></u
        >
      </p>
    </div>
    <div class="state state--mo">
      <h2>MISSOURI</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>Regulatory Agency: Missouri Division of Finance</p>
      <p>Regulator Address: 301 W. High Street, Room 630</p>
      <p>Jefferson City, MO 65101</p>
      <p>
        Phone:
        <a
          href="tel:573-751-4243"
          aria-label="Missouri Division of Finance Contact Number (573)-751-4243."
        >
          (573)-751-4243
        </a>
      </p>
      <p>
        Web Site:
        <u><a href="https://finance.mo.gov/"> https://finance.mo.gov/ </a></u>
      </p>
    </div>
    <div class="state state--ms">
      <h2>MISSISSIPPI</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446 Mississippi Registered Mortgage Company</p>
      <p>Regulatory Agency: MS Dept. of Banking and Consumer Finance</p>
      <p>Regulator Address: P.O. Box 12129,</p>
      <p>Jackson, MS 39236</p>
      <p>
        Phone:
        <a
          href="tel:(601) 321-6901"
          aria-label="MS Dept. of Banking and Consumer Finance Contact Number (601) 321-6901."
        >
          (601) 321-6901
        </a>
      </p>
      <p>Fax: (651) 296-8591</p>
      <p>
        Web Site:
        <u>
          &lt;
          <a href="https://www.dbcf.state.ms.us"
            >https://www.dbcf.state.ms.us</a
          ></u
        >
      </p>
    </div>
    <div class="state state--mt">
      <h2>MONTANA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>
        Regulatory Agency: MT Division of Banking and Financial Insititutions
      </p>
      <p>Regulator Address: 301 S. Park Ave., Suite 316,</p>
      <p>Helena, MT 59601</p>
      <p>
        Phone:
        <a
          href="tel:(406) 841-2920"
          aria-label="Montana Division of Banking and Financial Insititutions Contact Number 406-841-2920."
        >
          (406) 841-2920
        </a>
      </p>
      <p>
        Web Site:
        <u
          ><a href="https://banking.mt.gov/" class="text text-bolder"
            >https://banking.mt.gov/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--nb">
      <h2>NEBRASKA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>Regulatory Agency: NE Department of Banking and Finance</p>
      <p>Regulator Address: PO Box 95006,</p>
      <p>Lincoln, NE 68509</p>
      <p>
        Phone:
        <a
          href="tel:402-471-2171"
          aria-label="NE Department of Banking and Finance Contact Number (402) 471-2171."
        >
          (402) 471-2171
        </a>
      </p>
      <p>Fax: (651) 296-8591</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.banking.alabama.gov/"
            >https://www.banking.alabama.gov/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--nv">
      <h2>NEVADA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 4310</p>
      <p>Regulatory Agency: Nevada Division of Mortgage Lending</p>
      <p>Regulator Address: 1830 College Parkway, Suite 100,</p>
      <p>Carson City, NV 89706</p>
      <p>
        Phone:
        <a
          href="tel:775-684-7060"
          aria-label="Nevada Division of Mortgage Lending Contact Number (775) 684-7060."
        >
          (775) 684-7060
        </a>
      </p>
      <p>Fax:(775) 684-7061</p>
      <p>
        Web Site:
        <u><a href="https://mld.nv.gov/">https://mld.nv.gov/</a></u>
      </p>
    </div>
    <div class="state state--nj">
      <h2>NEW JERSEY</h2>
      <p>
        Gold Star Mortgage Financial Group is a licensed mortgage banker by the
        New Jersey Banking and Insurance Department
      </p>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>Regulatory Agency: New Jersey Department of Banking and Insurance</p>
      <p>Regulator Address: 20 West State Street, 8th Floor</p>
      <p>Trenton, NJ 08608</p>
      <p>
        Phone:
        <a
          href="tel:609-292-7272"
          aria-label="New Jersey Department of Banking and Insurance Contact Number (609) 292-7272."
        >
          (609) 292-7272
        </a>
      </p>
      <p>Fax: (609) 633-0822</p>
      <p>
        Web Site:
        <u
          ><a
            href="https://www.state.nj.us/dobi/division_banking/licensees/bnklic_menu.htm"
          >
            https://www.state.nj.us/dobi/division_banking/licensees/bnklic_menu.htm
          </a></u
        >
      </p>
    </div>
    <div class="state state--nm">
      <h2>NEW MEXICO</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>
        Regulatory Agency: NM Regulation and Licensing Dept. -- Financial
        Institutions
      </p>
      <p>Regulator Address: 2550 Cerrillos Rd., P.O. 25101,</p>
      <p>Santa Fe, NM, 87504</p>
      <p>
        Phone:
        <a
          href="tel:505-476-4885"
          aria-label="NM Regulation and Licensing Dept. Contact Number (505) 476-4885."
        >
          (505) 476-4885
        </a>
      </p>
      <p>
        Web Site:
        <u
          ><a href="https://www.rld.state.mn.us/financialinstitutions">
            https://www.rld.state.mn.us/financialinstitutions
          </a></u
        >
      </p>
    </div>
    <div class="state state--nc">
      <h2>NORTH CAROLINA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: L-145261</p>
      <p>
        Regulatory Agency: N.C. Commissioner of Banks Office-Mortgage Division
      </p>
      <p>Regulator Address: 4309 Mail Service Center,</p>
      <p>Raleigh, NC 27699-4309</p>
      <p>
        Phone:
        <a
          href="tel:919-733-3016"
          aria-label="N.C. Commissioner of Banks Office-Mortgage Division Contact Number (919) 733-3016."
        >
          (919) 733-3016
        </a>
      </p>
      <p>Fax: (919) 733-6918</p>
      <p>
        Web Site:
        <u><a href="https://www.nccob.gov">https://www.nccob.gov</a></u>
      </p>
    </div>
    <div class="state state--nd">
      <h2>NORTH DAKOTA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: MB102872</p>
      <p>Regulatory Agency: ND Dept. of Financial Institutions</p>
      <p>Regulator Address: 2000 Schafer Street, Suite G</p>
      <p>Bismarck, ND 58501</p>
      <p>
        Phone:
        <a
          href="tel:701-328-9933"
          aria-label="ND Dept. of Financial Institutions Contact Number (701) 328-9933."
        >
          (701) 328-9933
        </a>
      </p>
      <p>
        Web Site:
        <u><a href="https://www.nd.gov/dfi">www.nd.gov/dfi</a></u>
      </p>
    </div>
    <div class="state state--oh">
      <h2>OHIO</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>-National Home Lending</i></strong>
      </p>
      <p>
        <strong><i>-Certified Home Loans</i></strong>
      </p>
      <p>License Number: MBMB.850131.000, SM.501762.000</p>
      <p>
        Regulatory Agency: Ohio Division of Financial Institutions – Consumer
        Finance Licensing Section
      </p>
      <p>Regulator Address: 77 South High Street, 21st Floor</p>
      <p>Columbus, OH 43215</p>
      <p>
        Phone:
        <a
          href="tel:614-728-8400"
          aria-label="Ohio Division of Financial Institutions – Consumer Finance Licensing Section Contact Number (614) 728-8400."
        >
          (614) 728-8400
        </a>
      </p>
      <p>Fax: (614) 728-0380</p>
      <p>
        Web Site:
        <u><a href="https://www.com.ohio.gov">https://www.com.ohio.gov</a></u>
      </p>
    </div>
    <div class="state state--ok">
      <h2>OKLAHOMA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>-Certified Home Loans</i></strong>
      </p>
      <p>License Number: ML011806, ML011807</p>
      <p>Regulatory Agency: Oklahoma Department of Consumer Credit Licensing</p>
      <p>Regulator Address: 3613 NW 56th Street, Suite 240,</p>
      <p>Oklahoma City, OK 73112</p>
      <p>
        Phone:
        <a
          href="tel:405-521-3653"
          aria-label="Oklahoma Department of Consumer Credit Licensing Contact Number (405) 521-3653."
        >
          (405) 521-3653
        </a>
      </p>
      <p>
        Web Site:
        <u
          ><a href="https://www.ok.gov/okdocc/"
            >https://www.ok.gov/okdocc/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--or">
      <h2>OREGON</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: ML-4267</p>
      <p>Regulatory Agency: Division of Finance and Corporate Securities</p>
      <p>Regulator Address: 350 Winter Street NE, Room 410</p>
      <p>Salem, OR 97301</p>
      <p>
        Phone:
        <a
          href="tel:503-378-4140"
          aria-label="Division of Finance and Corporate Securities Contact Number (503) 378-4140."
        >
          (503) 378-4140
        </a>
      </p>
      <p>Fax: (503) 947-7862</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.cbs.state.or.us/dfcs/"
            >https://www.cbs.state.or.us/dfcs/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--pa">
      <h2>PENNSYLVANIA</h2>
      <p>
        Gold Star Mortgage Financial Group is licensed as a first mortgage
        banker by the Department of Banking and licensed pursuant to the
        Pennsylvania Second Mortgage Loan Act.
      </p>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 34582</p>
      <p>
        Regulatory Agency: Pennsylvania Department of Banking and Securities
      </p>
      <p>Regulator Address: 17 North Second Street, Suite 1300</p>
      <p>Harrisburg, PA 17101</p>
      <p>
        Phone:

        <a
          href="tel:(717) 787-2665"
          aria-label="Pennsylvania Department of Banking and Securities Contact Number (717) 787-2665."
        >
          (717) 787-2665
        </a>
      </p>
      <p>Fax: (717) 787-8773</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.banking.state.pa.us"
            >https://www.banking.state.pa.us</a
          ></u
        >
      </p>
    </div>
    <div class="state state--sc">
      <h2>SOUTH CAROLINA</h2>
      <p>
        Gold Star Mortgage Financial Group is licensed as a first mortgage
        banker by the Department of Banking and licensed pursuant to the South
        Carolina Second Mortgage Loan Act.
      </p>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: MLS-3446</p>
      <p>
        Regulatory Agency: South Carolina Consumer Finance Division of the Board
        of Financial Institutions
      </p>
      <p>Regulator Address: 1205 Pendleton Street, Suite 306,</p>
      <p>Columbia, SC 29201-3756</p>
      <p>
        Phone:

        <a
          href="tel:803-734-2020"
          aria-label="South Carolina Consumer Finance Division of the Board of Financial Institutions Contact Number (803) 734-2020."
        >
          (803) 734-2020
        </a>
      </p>
      <p>Fax: (717) 787-8773</p>
      <p>
        Web Site:
        <u
          ><a href="https://consumerfinance.sc.gov/"
            >https://consumerfinance.sc.gov/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--sd">
      <h2>SOUTH DAKOTA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: ML.05117</p>
      <p>Regulatory Agency: South Dakota Division of Banking</p>
      <p>Regulator Address: 1601 N. Harrison Ave., Suite</p>
      <p>Pierre, SD 57501</p>
      <p>
        Phone:

        <a
          href="tel:605-773-3421"
          aria-label="South Dakota Division of Banking Contact Number (605) 773-3421."
        >
          (605) 773-3421
        </a>
      </p>
      <p>Fax: (866) 326-7504</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.dlr.sd.gov/banking/"
            >https://www.dlr.sd.gov/banking/</a
          ></u
        >
      </p>
    </div>
    <div class="state state--tn">
      <h2>TENNESSEE</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>-National Home Lending</i></strong>
      </p>
      <p>
        <strong><i>-eFast Funding</i></strong>
      </p>
      <p>
        <strong><i>-Reach Home Loans</i></strong>
      </p>
      <p>License Number: 109413</p>
      <p>Regulatory Agency: Tennessee Department of Financial Institutions</p>
      <p>Regulator Address: 414 Union Street, Suite 1000</p>
      <p>Nashville, TN 37219</p>
      <p>
        Phone:
        <a
          href="tel:615-741-2236"
          aria-label="Tennessee Department of Financial Institutions Contact Number (615) 741-2236."
        >
          (615) 741-2236
        </a>
      </p>
      <p>Fax: (866) 326-7504</p>
      <p>
        Web Site:
        <u><a href="https://www.tn.gov/tdfi/">https://www.tn.gov/tdfi/</a></u>
      </p>
    </div>
    <div class="state state--tx">
      <h2>TEXAS</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>-National Home Lending</i></strong>
      </p>
      <p>
        <strong><i>-eFast Funding</i></strong>
      </p>
      <p>
        <strong><i>-Reach Home Loans</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>
        Regulatory Agency: Texas Department of Savings and Mortgage Lending
        (SML)
      </p>
      <p>Regulator Address: 2601 N. Lamar, Suite #201</p>
      <p>Austin, TX 78705</p>
      <p>
        Phone:
        <a
          href="tel:512-475-1350 "
          aria-label="Texas Department of Savings and Mortgage Lending (SML) Contact Number (512) 475-1350 ."
        >
          (512) 475-1350
        </a>
      </p>
      <p>Fax: (512) 475-1360</p>
      <p>
        Web Site:
        <u
          ><a href="https://www.sml.texas.gov/"
            >https://www.sml.texas.gov/</a
          ></u
        >
      </p>
      <p>
        TEXAS RECOVERY FUND NOTICE: GOLD STAR MORTGAGE FINANCIAL GROUP,
        CORPORATION IS LICENSED UNDER THE LAWS OF THE STATE OF TEXAS AND BY
        STATE LAW IS SUBJECT TO REGULATORY OVERSIGHT BY THE DEPARTMENT OF
        SAVINGS AND MORTGAGE LENDING. ANY CONSUMER WISHING TO FILE A COMPLAINT
        AGAINST GOLD STAR MORTGAGE FINANCIAL GROUP, CORPORATION SHOULD COMPLETE,
        SIGN, AND SEND A COMPLAINT FORM TO THE DEPARTMENT OF SAVINGS AND
        MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN, TEXAS 78705.
        COMPLAINT FORMS AND INSTRUCTIONS MAY BE DOWNLOADED AND PRINTED FROM THE
        DEPARTMENT’S WEB SITE LOCATED AT
        <a href="http://www.sml.state.tx.us">http://www.sml.state.tx.us</a>
        OR OBTAINED FROM THE DEPARTMENT UPON REQUEST BY MAIL AT THE ADDRESS
        ABOVE, BY TELEPHONE AT ITS TOLL-FREE CONSUMER HOTLINE AT
        <a href="tel:877-276-5550">1-877-276-5550</a>
        , BY FAX AT (512) 475-1360, OR BY E-MAIL AT
        <a href="mailto:SMLINFO@SML.STATE.TX.US">SMLINFO@SML.STATE.TX.US</a>
        . THE DEPARTMENT MAINTAINS THE MORTGAGE BROKER RECOVERY FUND TO MAKE
        PAYMENTS OF CERTAIN ACTUAL OUT OF POCKET DAMAGES SUSTAINED BY BORROWERS
        CAUSED BY ACTS OF LICENSED RESIDENTIAL MORTGAGE LOAN ORIGINATORS. A
        WRITTEN APPLICATION FOR REIMBURSEMENT FROM THE RECOVERY FUND MUST BE
        FILED WITH AND INVESTIGATED BY THE DEPARTMENT PRIOR TO THE PAYMENT OF A
        CLAIM. FOR MORE INFORMATION ABOUT THE RECOVERY FUND, PLEASE CONSULT
        SUBCHAPTER F OF THE MORTGAGE BROKER LICENSE ACT ON THE DEPARTMENT’S WEB
        SITE REFERENCED ABOVE
      </p>
    </div>
    <div class="state state--ut">
      <h2 class="text text-uppercase">Utah-DRE</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 6401349</p>
      <p>Regulatory Agency: Utah Division of Real Estate</p>
      <p>Regulator Address: 160 East 300 South, Second Floor</p>
      <p>Salt Lake City, UT 84111</p>
      <p>
        Phone:
        <a
          href="tel:801-530-6747"
          aria-label="Utah Division of Real Estate Contact Number (801) 530-6747."
        >
          (801) 530-6747
        </a>
      </p>
      <p>
        Web Site:
        <a href="https://realestate.utah.gov" class="text text-bolder"
          >https://realestate.utah.gov</a
        >
      </p>
    </div>
    <div class="state state--ut">
      <h2 class="text text-uppercase">Utah-DFI</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: 3446</p>
      <p>Regulatory Agency: Utah Department of Financial Institutions</p>
      <p>Regulator Address: 324 S. State Street, Suite 201</p>
      <p>Salt Lake City, UT 84111</p>
      <p>
        Phone:
        <a
          href="tel:801-538-8830"
          aria-label="Utah Department of Financial Institutions Contact Number (801) 538-8830."
        >
          (801) 538-8830
        </a>
      </p>
      <p>
        Web Site:
        <a href="https://dfi.utah.gov/" class="text text-bolder"
          >https://dfi.utah.gov/</a
        >
      </p>
    </div>
    <div class="state state--va">
      <h2>VIRGINIA</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>License Number: MC-5580</p>
      <p>Regulatory Agency: Bureau of Financial Institutions</p>
      <p>Regulator Address: PO Box 640,</p>
      <p>Richmond, VA 23218-0640</p>
      <p>
        Phone:
        <a
          href="tel:804-371-0484"
          aria-label="Bureau of Financial Institutions Contact Number   (804) 371-0484."
        >
          (804) 371-0484
        </a>
      </p>
      <p>
        Web Site:
        <u
          ><a href="https://www.scc.virginia.gov"
            >https://www.scc.virginia.gov</a
          ></u
        >
      </p>
    </div>
    <div class="state state--wa">
      <h2>WASHINGTON</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>-Rainer Mortgage</i></strong>
      </p>
      <p>License Number: CL-3446</p>
      <p>
        Regulatory Agency: Department of Financial Institutions-Division of
        Consumer Services
      </p>
      <p>Regulator Mailing Address: PO Box 41200 Olympia WA 98504-1200</p>
      <p>Regulator Address:150 Israel Rd SW</p>
      <p>Tumwater, WA 98512</p>
      <p>
        Phone:
        <a
          href="tel:360-902-8703"
          aria-label="Department of Financial Institutions-Division of Consumer Services Contact Number (360) 902-8703."
        >
          (360) 902-8703
        </a>
      </p>
      <p>TDD: (360) 664-8126</p>
      <p>
        Web Site:
        <u><a href="https://dfi.wa.gov">https://dfi.wa.gov</a></u>
      </p>
    </div>
    <div class="state state--wi">
      <h2>WISCONSIN</h2>
      <p>Approved Names:</p>
      <p>
        <strong><i>-Gold Star Mortgage Financial Group, Corporation</i></strong>
      </p>
      <p>
        <strong><i>-Rainer Mortgage</i></strong>
      </p>
      <p>License Number: 3446BA, 215139BR</p>
      <p>Regulatory Agency: Department of Financial Institutions</p>
      <p>
        Regulator Mailing Address: 201 W. Washington Ave., 5th Floor, Madison,
        WI 53703
      </p>
      <p>
        Phone:
        <a
          href="tel:608-261-7578"
          aria-label="Department of Financial Institutions Contact Number (608) 261-7578."
        >
          (608) 261-7578
        </a>
      </p>
      <p>
        Web Site:
        <u
          ><a href="https://www.wdfi.org/fi/mortbank/"
            >https://www.wdfi.org/fi/mortbank/</a
          ></u
        >
      </p>
    </div>
    <p>
      <strong>Gold Star Mortgage Financial Group</strong>
      is an Equal Housing Lender. We do not engage in any business practices
      that discriminate on the basis of race, color, religion, national origin,
      sex, marital status, age, because all or part of your income may be
      derived from any public assistance program, or because you have, in good
      faith, exercised any right under the Consumer Credit Protection Act.
    </p>
    <p>
      <strong>Gold Star Mortgage Financial Group</strong>
      does business in accordance with Federal Fair Lending Laws. Under the
      Equal Credit Opportunity Act it is illegal to discriminate in any credit
      transaction on the basis of race, color, national origin, religion, sex
      marital status, or age; because income is from public assistance; or
      because a right has been exercised under the Federal Consumer Credit
      Protection Laws or the Service members Civil Relief Act. If you believe
      you have been discriminated against, you should send a complaint to one or
      more of the following, or you may submit one on our website.
    </p>
    <p>
      <strong> U.S. Dept. of Justice Civil Rights Division </strong>
      Housing and Civil Enforcement Section Washington, D.C. 20530
      <u><a href="https://usdoj.gov/crt/housing">usdoj.gov/crt/housing</a></u>
    </p>
    <p></p>
    <p>
      <strong>Consumer Financial Protection Bureau -- Midwest Division</strong>
      P.O. Box 4503 Iowa City, Iowa 52244 (855) 411-2372 (855) 729-2372
      (TTY/TDD)
      <u
        ><a href="https://www.consumerfinance.gov"
          >www.consumerfinance.gov</a
        ></u
      >
    </p>
    <p>
      <strong>Federal Trade Commission</strong>
      Washington, D.C. 20580 (202) 326-2222
      <u
        ><a href="https://www.ftccomplaintassistant.gov"
          >ftccomplaintassistant.gov</a
        ></u
      >
    </p>
  </div>
</template>
