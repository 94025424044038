<template>
  <div role="main" class="container">
    <h1 class="title title--base">Do Not Sell My Personal Information</h1>
    <h2 class="title title--secondary">
      Applicable to California residents only
    </h2>
    <div>
      <p class="paragraph paragraph--base">
        The California Consumer Privacy Act of 2018 (“CCPA”) and the California
        Privacy Rights Act of 2020 (“CPRA”), collectively referred to as
        “California Consumer Privacy Laws,” allow you to limit the sale and
        sharing of your personal information.
      </p>
      <p class="paragraph paragraph--base">
        <b>Gold Star does not sell any of your personal information.</b> For
        more information, please see the separate link on our website to our
        California Privacy Policy.
      </p>
      <p class="paragraph paragraph--base">
        Gold Star respects and understands that you may still want to ensure
        your personal information is not sold. Therefore, if you would still
        like to proceed with a “Do Not Sell My Information” request, we ask that
        you provide the information requested below.
      </p>
      <h1 class="title title--base">
        Limit the Use or Disclosure of My Sensitive Personal Information
      </h1>
      <p class="paragraph paragraph--base">
        The California Consumer Privacy Laws also allow you to limit the use or
        disclosure of Sensitive Personal Information (“SPI”). Gold Star does not
        sell any of your SPI. For more information, please see the separate link
        on our website to our California Privacy Policy.
      </p>
      <p class="paragraph paragraph--base">
        By submitting this request, you are attesting and verifying that you are
        a California resident availing yourself of the rights afforded under the
        California Consumer Privacy Laws.
      </p>
    </div>
    <form enctype="plain/text" action="mailto:privacy@goldstarfinancial.com?subject=Do%20not%20sell%20my%20information" method="post">
      <fieldset>
        <legend >Contact Form</legend>
        <div class="two-field-container">
          <div class="field-container">
            <label for="firstName"> First Name </label>
            <div>
              <input id="firstName" type="text" name="FirstName"/>
            </div>
          </div>
          <div class="field-container">
            <label for="lastName"> Last Name </label>
            <div>
              <input id="lastName" type="text" name="LastName"/>
            </div>
          </div>
        </div>
        <div class="two-field-container">
          <div class="field-container">
            <label for="email"> Email </label>
            <div>
              <input id="email" type="email" name="Email"/>
            </div>
          </div>
          <div class="field-container">
            <label for="address"> Address </label>
            <div class="">
              <input id="address" type="text" name="Address" />
            </div>
          </div>
        </div>
        <div class="two-field-container">
          <div class="field-container">
            <label for="city" class=""> City </label>
            <div class="">
              <input id="city" type="text" name="City"/>
            </div>
          </div>
          <div class="two-field-container">
              <div class="field-container">
                <label for="state" class=""> State </label>
                <div class="">
                  <input id="state" type="text" name="State" />
                </div>
              </div>
              <div class="field-container">
                <label for="zip" class=""> Zip </label>
                <div class="">
                  <input id="zip" type="text" name="Zip" />
                </div>
              </div>    
            </div>
        </div>
        <div class="two-field-container">
            <div class="field-container">
              <label for="loanNumber" class=""> Loan Number </label>
              <div class="">
                <input id="loanNumber" type="text" name="LoanNumber" />
              </div>
            </div>
            <div class="field-container">
              <label for="requestComment" class="">
                Request Comment
              </label>
              <div class="">
                <input id="requestComment" type="text" name="Message" />
              </div>
            </div>

        </div>
        <div class="">
          <input type="submit" class="btn-submit" value="Send"/>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
export default{
    data: function() {
        return {
            fname: ""
        }
    }
}

</script>
