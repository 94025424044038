<template>
  <div class="container">
    <br /><br />

    <p>
      <strong class="text-blue font-size-18"
        >CALIFORNIA CONSUMER PRIVACY POLICY FOR CALIFORNIA RESIDENTS</strong
      >
    </p>

    <p>Last Update/Effective Date: January 1, 2023</p>

    <p>
      This California Consumer Notice at Collection and Privacy Policy (“Policy”
      or “Notice”) supplements and is expressly made part of the information
      contained in the Privacy Policy of GOLD STAR MORTGAGE FINANCIAL GROUP,
      CORPORATION (collectively, “Gold Star”, “We,” “Us,” or “Our”) and applies
      solely to consumers, visitors, users, and others who reside in the State
      of California (“Consumers”, “You” or “Your”). We adopt this Policy to
      comply with the California Consumer Privacy Act of 2018 and, as amended,
      the California Privacy Rights Act of 2020 (together, “CPRA”), and other
      California privacy laws. Any terms defined in the CPRA or its implementing
      regulations have the same meaning when used in this Policy.
    </p>

    <p>
      <br />
      <strong class="text-blue">Purpose and Coverage</strong>
      <br />
    </p>

    <p>
      The purpose of this Policy is to comply with California law and provide
      You with a comprehensive description of Our online and offline practices
      regarding collection, use, and disclosure of personal information (“PI”)
      (as defined in the CPRA) and of Your rights regarding Your personal
      information.
    </p>

    <section id="CollectedInformation">
      <p>
        <strong class="underline"> Information We Collect </strong>
      </p>
      <p>
        We collect information that identifies, relates to, describes,
        references, is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with a particular Consumer or household
        (“personal information”). In particular, Gold Star has collected the
        following categories of personal information from Consumers within the
        last twelve (12) months:
      </p>

      <div class="CollectionTable">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Examples</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A. Identifiers</td>
              <td>
                A real name, alias, postal address, unique personal identifier,
                online identifier, Internet Protocol address, email address,
                account name, Social Security number, driver's license number,
                passport number, or other similar identifiers.
              </td>
            </tr>
            <tr>
              <td>
                B. Personal information categories listed in the California
                Customer Records statute (Cal. Civ. Code § 1798.80(e)).
              </td>
              <td>
                A name, signature, Social Security number, physical
                characteristics or description, address, telephone number,
                passport number, driver's license or state identification card
                number, insurance policy number, education, employment,
                employment history, bank account number, credit card number,
                debit card number, or any other financial information, medical
                information, or health insurance information. Some personal
                information included in this category may overlap with other
                categories.
              </td>
            </tr>

            <tr>
              <td>
                C. Protected classification characteristics under California or
                federal law.
              </td>
              <td>
                Age (40 years or older), race, color, ancestry, national origin,
                citizenship, religion or creed, marital status, medical
                condition, physical or mental disability, sex/gender, sexual
                orientation, veteran or military status, genetic information
                (including familial genetic information), and receipt of public
                assistance.
              </td>
            </tr>

            <tr>
              <td>D. Commercial information.</td>
              <td>
                Records of personal property, products or services purchased,
                obtained, or considered, or other purchasing or consuming
                histories or tendencies.
              </td>
            </tr>

            <tr>
              <td>E. Biometric information.</td>
              <td>Voice recordings as part of call recordings.</td>
            </tr>
            <tr>
              <td>F. Internet or other similar network activity.</td>
              <td>
                Browsing history, search history, information on a consumer's
                interaction with a website, application, or advertisement.
              </td>
            </tr>
            <tr>
              <td>G. Geolocation data.</td>
              <td>Physical location or movements.</td>
            </tr>
            <tr>
              <td>H. Sensory data.</td>
              <td>
                Audio, electronic, or similar information, as part of any voice
                call recordings.
              </td>
            </tr>

            <tr>
              <td>I. Professional or employment-related information.</td>
              <td>Current or past job history or performance evaluations.</td>
            </tr>
            <tr>
              <td>
                J. Non-public education information (per the Family Educational
                Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
                99)).
              </td>
              <td>
                Educational records directly related to a student that are
                maintained by an educational institution such as years in school
                or student loans obtained.
              </td>
            </tr>
            <tr>
              <td>K. Inferences drawn from other personal information.</td>
              <td>
                Derivation of information, data, assumptions, conclusions, from
                facts, evidence or another source of data drawn from any of the
                information identified above to create a profile about a
                Consumer reflecting the Consumer’s preferences, characteristics,
                or tendencies with respect to obtaining a mortgage loan.
              </td>
            </tr>
            <tr>
              <td>L. Sensitive Personal Information.</td>
              <td>
                Social Security number, driver’s license number, or passport
                number.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>Personal information does not include:</p>
      <ul>
        <li>Publicly available information from government records.</li>
        <li>De-identified or aggregated consumer information.</li>
        <li>
          Information excluded from the CPRA's scope, like:
          <ul>
            <li>
              Health or medical information covered by the Health Insurance
              Portability and Accountability Act of 1996 (HIPAA) and the
              California Confidentiality of Medical Information Act (CMIA) or
              clinical trial data;
            </li>
            <li>
              Personal information covered by certain sector-specific privacy
              laws, including the Fair Credit Reporting Act (FRCA), the
              Gramm-Leach-Bliley Act (GLBA) or California Financial Information
              Privacy Act (FIPA), and the Driver's Privacy Protection Act of
              1994.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        We obtain the categories of personal information listed above from the
        following categories of sources:
      </p>
      <ul>
        <li>
          Directly from Our Consumers or their agents. For example, from
          documents that Our Consumers provide to Us related to the products and
          services for which they engage Us.
        </li>
        <li>
          Indirectly from Our Consumers or their agents. For example, through
          information We collect from Our Consumers in the course of providing
          products or services to them.
        </li>
        <li>
          Records and copies of Your correspondence (including email addresses)
          if You contact Us.
        </li>
        <li>
          Directly and indirectly from activity on Our website
          (www.goldstarfinancial.com). For example, from submissions through Our
          website application portal or website usage details collected
          automatically.
        </li>
        <li>
          From third-party business partners that interact with Us in connection
          with the services We perform or the products We provide. For example,
          from government agencies when We underwrite, close, or fund a mortgage
          loan.
        </li>
      </ul>
    </section>

    <section id="UseOfInformation">
      <p>
        <strong class="underline"> Use of Personal Information </strong>
      </p>
      <p>
        We or our service providers use Your personal information for Our
        operational purposes, or other notified purposes, which uses We strive
        to ensure are reasonably necessary and proportionate to achieve Our
        operational purposes for which We collect Your information in the first
        place, or for which We process Your information, or for another
        operational purpose that is compatible with the context in which We
        collect Your personal information. For example, the following are
        business purposes for which We collect or share Your information:
      </p>
      <ul>
        <li>To present the content of Our products and services to You.</li>
        <li>
          To provide You with information, products, or services that You
          request from Us.
        </li>
        <li>To fulfill any other purpose for which You provide it.</li>
        <li>
          To provide You with notices about Your account, including expiration
          and renewal notices.
        </li>
        <li>
          To carry out Our obligations and enforce Our rights arising from any
          contracts entered into between You and Us, including for billing and
          collection.
        </li>
        <li>
          To notify You about changes to Our products or services We offer or
          provide through it.
        </li>
        <li>
          In any other way We may describe when You provide the information.
        </li>
        <li>For any other purpose with Your consent.</li>
        <li>
          As appropriate, in our sole discretion, for other legitimate business
          activities.
        </li>
      </ul>
      <p>
        We may use or disclose the personal information We collect for one or
        more of the following business purposes:
      </p>
      <ul>
        <li>
          To fulfill or meet the reason You provided the information. For
          example, if You disclose Your name and contact information to request
          a rate quote or ask a question about Our products or services, We will
          use that personal information to respond to Your inquiry. If You
          provide Your Personal Information to purchase a product or service, We
          will use that information to process Your payment and facilitate
          delivery. We may also save Your information to facilitate new product
          orders or process returns.
        </li>
        <li>
          To provide support, personalize, and develop Our services, products,
          and other services.
        </li>
        <li>
          To create, maintain, customize, and secure Your account with Us.
        </li>
        <li>
          To process Your requests, purchases, transactions, and payments and
          prevent transactional fraud.
        </li>
        <li>
          To provide You with support and to respond to Your inquiries,
          including to investigate and address Your concerns and monitor and
          improve Our responses.
        </li>
        <li>
          To personalize Your services experience and to deliver content and
          product and service offerings relevant to Your interests, including
          targeted offers and ads through Our services, third-party sites, and
          via email or text message (with Your consent, where required by law).
        </li>
        <li>To provide advertising and marketing services.</li>
        <li>
          To help maintain the safety, security, and integrity of Our services,
          products, and other services, databases, and other technology assets,
          and business.
        </li>
        <li>
          For testing, research, analysis, and product development, including to
          develop and improve Our Services, products, and other services.
        </li>
        <li>
          To respond to law enforcement requests and as required by applicable
          law, court order, or governmental regulations.
        </li>
        <li>
          As described to You when collecting Your personal information or as
          otherwise set forth in the CPRA.
        </li>
        <li>
          To evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all
          of Our assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which personal information held
          by Us about Our services users is among the assets transferred.
        </li>
        <li>
          Auditing related to counting ad impressions to unique visitors,
          verifying positioning and quality of ad impressions, and auditing
          compliance with this specification and other standards.
        </li>
        <li>
          Helping to ensure security and integrity to the extent the use of the
          Consumer’s personal information is reasonably necessary and
          proportionate for these purposes.
        </li>
        <li>
          Debugging to identify and repair errors that impair existing intended
          functionality.
        </li>
        <li>
          Short-term, transient use, including, but not limited to,
          non-personalized advertising shown as part of a Consumer’s current
          interaction with Us.
        </li>
        <li>
          Performing services on behalf of the business, including maintaining
          or servicing accounts, providing customer service, processing, or
          fulfilling orders and transactions, verifying customer information,
          processing payments, providing financing, providing analytic services,
          providing storage, or providing similar services on behalf of the
          business.
        </li>
        <li>
          Undertaking internal research for technological development and
          demonstration.
        </li>
        <li>
          Undertaking activities to verify or maintain the quality or safety of
          a product, service or device that is owned, manufactured, manufactured
          for, or controlled by the business, and to improve, upgrade, or
          enhance the product, service or device that is owned, manufactured,
          manufactured for, or controlled by the business.
        </li>
      </ul>
    </section>

    <section id="SharingPersonalInfo">
      <p>
        <strong class="underline"> Sharing Personal Information </strong>
      </p>
      <p>
        All financial companies need to disclose personal information to run
        their everyday business and provide the services You hope to obtain. We
        may disclose Your personal information to a third party for a business
        purpose. When We disclose personal information for a business purpose,
        We typically enter into a contract that describes the purpose and
        requires the recipient to both keep that personal information
        confidential and not use it for any purpose except performing the
        contract.
      </p>

      <p>
        In the preceding twelve (12) months, We have disclosed the above
        categories of personal information for a business purpose to the
        following categories of third parties:
      </p>
      <ul>
        <li>
          <u>Authorized third parties.</u> We will disclose Your information to
          a third party with Your consent.
        </li>
        <li>
          <u>Affiliates, subsidiaries, and businesses under Our control.</u> We
          may disclose Your personal information to our affiliates,
          subsidiaries, and companies under common control.
        </li>
        <li>
          <u>Service providers, contractors, and third parties.</u> We may
          disclose personal information to service providers, vendors, or
          contractors, or they may disclose, collect, store, access, transfer,
          or otherwise process Your personal information on Our behalf to help
          Us carry out Our services or run Our business. We require service
          providers, vendors, and contractors to implement reasonable
          organizational controls to maintain the privacy and security of
          personal information provided to them.
        </li>
        <li>
          <u>Government, regulatory, and legal entities.</u> We disclose Your
          personal information to government, regulatory, and legal entities in
          response to their inquiries, to enforce our terms and conditions, to
          exercise or defend a claim, or if We determine disclosure is necessary
          or appropriate to protect the life, safety, or property of Our
          business, ourselves, or others.
        </li>
      </ul>

      <p>
        We will comply with other federal and state laws and regulations that
        impact Our disclosure practices.
      </p>

      <p>
        <strong class="underline"> Sales of Personal Information </strong>
      </p>

      <p>
        Gold Star does not sell consumer information nor have We sold personal
        information in the preceding 12 months outside of the permitted sharing,
        disclosures, or transfers made pursuant to the exemptions of the
        Gramm-Leach-Bliley Act or the Fair Credit Reporting Act. We have no
        actual knowledge of selling or sharing personal information of anyone
        under the age of 16 years old. Notwithstanding the foregoing, We will
        accept a verified request from You to not sell or share Your personal
        information should Our practices change in the future.
      </p>

      <p>
        <strong class="underline">
          Retention of Your Personal Information
        </strong>
      </p>

      <p>
        We retain Your personal information throughout Our business relationship
        with You and thereafter only for as long as required to fulfil the
        purposes for which it was collected. However, in some circumstances We
        may retain personal information for longer periods of time, for instance
        when We are required to do so in accordance with legal and regulatory
        requirements or to resolve disputes that may arise between You and Us.
      </p>
    </section>

    <section id="YourRights">
      <p>
        <strong class="underline"> Your Rights and Choices </strong>
      </p>
      <p>
        The CPRA provides Consumers (California residents) with specific rights
        regarding their personal information. This section describes Your CPRA
        rights and explains how to exercise those rights.
      </p>
      <ol>
        <li>The right to know Your personal information;</li>
        <li>
          The right to delete personal Information collected from You, subject
          to certain exceptions;
        </li>
        <li>
          The right to opt-out of the sale or sharing of personal information
          (if applicable);
        </li>
        <li>
          The right to non-discriminatory treatment or no retaliation for
          exercising any rights;
        </li>
        <li>
          The right to request the categories of personal information disclosed
          about You for a business purpose;
        </li>
        <li>The right to correct inaccurate personal information;</li>
        <li>
          The right to limit use and disclosure of sensitive personal
          information (if applicable), for reasons other than those set forth in
          section 7027, subsection (m) of the CPRA.
        </li>
      </ol>

      <p>
        When You make a request, We may require that You provide information and
        follow procedures so that We can verify the request and Your
        jurisdiction before responding to it. The verification steps We take may
        differ depending on the request You make. We will match the information
        that You provide in Your request to information We already have on file
        to verify Your identity. If We can verify Your request, We will process
        it. If We cannot verify Your request, We may ask You for additional
        information to help Us verify Your request.
      </p>

      <p>
        Consumers will be required to submit their first and last name and email
        address and may also be asked to provide their telephone number or
        address so that We can verify the request. Please provide as much of the
        requested information as possible to help Us verify the request. We will
        only use the information received in a request for the purposes of
        responding to the request.
      </p>

      <p>
        California law permits California Consumers to use an authorized agent
        to make privacy rights requests. We require the authorized agent to
        provide Us with proof of the California Consumer’s written permission
        (for example, a power of attorney) that shows the authorized agent has
        the authority to submit a request for the California Consumer. An
        authorized agent must follow the process described below to make a
        request. The authorized agent must also verify his/her own identity. We
        will confirm the agent’s authority with the California Consumer about
        whom the request was made.
      </p>

      <p>
        The right to request deletion is not absolute and is subject to several
        restrictions and exemptions. Gold Star reserves all rights granted
        pursuant to the CPRA’s exemptions and restrictions relating to the
        deletion of personal information.
      </p>

      <p>
        We may deny Your deletion request if We cannot verify Your identity, if
        We cannot verify the authorization of Your agent, if the information was
        not collected from You, or is otherwise not subject to the rights
        granted by the CPRA, or if retaining the information is necessary for Us
        or Our service providers to:
      </p>

      <ol>
        <li>
          Complete the transaction for which We collected the personal
          information, provide a product or service that You requested, take
          actions reasonably anticipated within the context of Our ongoing
          business relationship with You, or otherwise perform Our contract with
          You.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, or prosecute those responsible for
          such activities.
        </li>
        <li>
          Debug products to identify and repair errors that impair existing
          intended functionality.
        </li>
        <li>
          Exercise free speech, ensure the right of another Consumer to exercise
          their free speech rights, or exercise another right provided for by
          law.
        </li>
        <li>
          Comply with the California Electronic Communications Privacy Act (Cal.
          Penal Code § 1546 seq.).
        </li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when the information’s deletion
          may likely render impossible or seriously impair the research’s
          achievement, if You previously provided informed consent.
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with Consumer
          expectations based on Your relationship with Us.
        </li>
        <li>Comply with a legal obligation.</li>
        <li>
          Make other internal and lawful uses of that information that are
          compatible with the context in which You provided it.
        </li>
      </ol>

      <p>
        In the event that We receive a “Verified Request” to delete information
        that is not covered by a restriction or exemption, We will notify You
        within 45 days and delete Your personal information from our records,
        and direct any service providers to delete Your personal information
        from its records. In the event that We receive a “Verified Request” to
        delete information that is covered by a restriction or exemption, We
        will deny Your request and within 45 days provide You a response
        indicating why it is denied.
      </p>

      <p>You may submit a verified request to Us by:</p>
      <ul>
        <li>Calling Us at <a href="tel:1-833-635-0168">833-635-0168</a></li>
        <li>
          Emailing Us at
          <a href="mailto:privacy@goldstarfinancial.com"
            >privacy@goldstarfinancial.com</a
          >
        </li>
        <li>
          Sending Us a letter to: Gold Star Mortgage Financial Group,
          Corporation, Attn: Chief Compliance Officer, 100 Phoenix Drive, Suite
          300, Ann Arbor, Michigan 48108.
        </li>
        <li>
          Submitting a request through the “Do Not Sell My Information” link and
          form located on Gold Star’s website.
        </li>
      </ul>

      <p>
        <strong><u>Note:</u></strong> Gold Star does not “sell” or “share” (as
        defined in the CPRA) Consumer information. Additionally, Gold Star does
        not use or disclose sensitive personal information for purposes other
        than those specified in section 7027, subsection (m) of the CPRA.
      </p>

      <p>
        <strong class="underline"> Minors </strong>
      </p>
      <p>
        We do not knowingly collect, process, use, sell, or share personal
        information of those 16 years old or younger.
      </p>

      <p>
        <strong class="underline"> Notice to Job Applicants </strong>
      </p>
      <p>
        If You are a California resident and You have applied for a job with Us,
        are employed by Us, or Your employment with Us has ceased, please read
        our California Applicant Notice at Collection and Privacy Policy here,
        or Our California Employee Privacy Notice here, as applicable.
      </p>
    </section>

    <section id="NonDiscrimination">
      <p>
        <strong class="underline"> Non-Discrimination </strong>
      </p>
      <p>
        We will not discriminate against You for exercising any of Your CPRA
        rights. Unless permitted by the CPRA, We will not:
      </p>
      <ul>
        <li>Deny You goods or services.</li>
        <li>
          Charge You different prices or rates for goods or services, including
          through granting discounts or other benefits, or imposing penalties.
        </li>
        <li>Provide You a different level or quality of goods or services.</li>
        <li>
          Suggest that You may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ul>
    </section>

    <section id="ChangesToPolicyNotice">
      <p>
        <strong class="underline"> Changes to Our Privacy Notice </strong>
      </p>
      <p>
        We reserve the right to amend this Policy at Our discretion and at any
        time. When We make changes to this Policy, We will notify You by email
        or through a notice on Our website homepage. Your continued use of Our
        website following the posting of changes constitutes Your acceptance of
        such changes.
      </p>
    </section>

    <section id="ContactInformation">
      <p>
        <strong class="underline"> Contact Information </strong>
      </p>

      <p>
        If You have any questions, comments, or concerns about this Policy
        and/or Our practices, please do not hesitate to contact Us at:
      </p>

      <p><strong>Phone:</strong> <a href="tel:833-635-0168">833-635-0168</a></p>
      <p>
        <strong>Email:</strong>
        <a href="mailto:privacy@goldstarfinancial.com"
          >privacy@goldstarfinancial.com</a
        >
      </p>
      <p class="mb-0"><strong>Sending a letter to Us at:</strong></p>
      <p class="m-0">GOLD STAR MORTGAGE FINANCIAL GROUP, CORPORATION</p>
      <p class="m-0">Attn: Chief Compliance Officer</p>
      <p class="m-0">100 Phoenix Drive, Suite 300</p>
      <p class="m-0">Ann Arbor, Michigan 48108</p>
    </section>
  </div>
</template>
