<template>
    <LOHeader :loinfo="loinfo"></LOHeader>
    <contactForm :loinfo="loinfo"></contactForm>
    <LOFooter :loinfo="loinfo" style="bottom: 0; padding: 0; margin: 0;"></LOFooter>
</template>

<script>
import LOHeader from "@/components/LOHeader.vue";
import contactForm from "@/components/contactForm.vue";
import LOFooter from "@/components/LOFooter.vue";
import apiService from "../api/api.service.js";

    export default {
        props: {
        },
        data: function() {
            return {
                larFromQuery: "",
                loinfo: []
            }
        },
        components: {
            contactForm,
            LOHeader,
            LOFooter
        },
        methods: {
            getLOInfo() {
      return apiService
        .GetLOPage(this.larFromQuery)
        .then((res) => {
          this.loinfo = res.data.data[0];
          document.title = "Contact " + 
          this.loinfo.first_name + " " +
          this.loinfo.last_name;

          this.getReviews();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.larFromQuery = params.lar;
  },
  mounted() {
    this.getLOInfo();
    document.getElementById('loaderContainer').classList.add('dissolve');
        }
    }
</script>
