<template>
  <div class="container">
    <div>
      <p><strong>Gold Star Mortgage Financial Group, Corp</strong></p>
      <p><strong>Website Privacy Policy</strong></p>
      <p>&nbsp;</p>
      <p>Effective Date:&nbsp;Revised, February 28, 2018</p>
      <p>
        <br />
        This policy describes&nbsp;Gold Star Mortgage Financial Group,
        Corp&nbsp;(“Gold Star,” “we” or “our”) collection, processing, transfer
        and storage of data from visitors to our website at&nbsp;
        <a href="http://www.goldstarfinancial.com">www.goldstarfinancial.com</a>
        &nbsp;(our “Website”). &nbsp;This Privacy Policy applies only to the
        data collected on the Website and not to other data collected or
        processed by&nbsp;Gold Star.
      </p>
      <p>
        <br />
        If you are a&nbsp;Gold Star&nbsp;customer, please see our&nbsp;
        <u>Consumer Privacy Notice</u>, which we provide to all our individual
        customers, for a comprehensive explanation of how we collect, use, and
        share personal information and your choices for limiting the use and
        sharing of your information as our customer.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p><strong>1. OUR COMMITMENT TO YOUR PRIVACY</strong></p>
      <p>This Privacy Policy describes:</p>
      <p>&nbsp;</p>
      <ul>
        <li>How information about you is collected through our Website;</li>
        <li>How we use and with whom we share this information;</li>
        <li>How you can access and update this information; and</li>
        <li>
          The choices you can make about how we collect, use and share your
          information.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        If you have any questions regarding this Privacy Policy or our
        information collection and use practices, please contact us using the
        information in the “Contact Us” section below.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>2. INFORMATION COLLECTED THROUGH OUR WEBSITE</strong> <br />
        Gold Star&nbsp;collects information from Website visitors and also
        obtains information from third parties (including from service
        providers) in the course of providing our mortgage and loan origination
        services (our “Services”), which we may add to the data we obtain
        through the Website. &nbsp;&nbsp;The information collected through our
        website includes:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <u>Personal and Loan-related Information</u>. You may provide us
          information about you, which may include personal information and
          information relating to or necessary for the initiation, processing,
          or completion of certain financial transactions, including the
          issuance of loans. &nbsp;
        </li>
        <li>
          <u>Transactional Information</u>. We may also create transactional
          records of each of the transactions or other events occurring through
          our Website. This transactional information is generally collected (i)
          to enable our Services, including loan document creation, origination,
          management, and distribution, (ii) to enable the services provided by
          third parties (including determinations regarding qualification for
          loans or the interest rates to be applied to loans), and (iii) to
          enable&nbsp;Gold Star to comply with various legal obligations.&nbsp;
        </li>
        <li>
          <u>Device and Usage Information</u>. Like most online services, our
          Website collects standard technical, non-personal information when you
          use it, including internet protocol (IP) addresses, browser types,
          internet service providers (ISPs), the pages and content you view or
          interact with on our Website, the information you search for, and the
          dates and times that you visit our Website.&nbsp;
        </li>
        <li>
          <u>Cookies and Other Technologies</u>. Our Website collects certain
          information through the use of “cookies” and other tracking
          technologies. Cookies are small files that your browser places on your
          computer. We may use session cookies, persistent cookies, and other
          tracking technologies to better understand how you interact with our
          Website, to monitor usage by our users and web traffic routing on our
          services, and to improve and personalize our Website. Many Internet
          browsers automatically accept cookies. You may be able to instruct
          your browser to stop accepting cookies or to prompt you before
          accepting cookies from the websites you visit. Google provides some
          additional privacy options relating to Google analytics, described
          at&nbsp;
          <a href="http://www.google.com/policies/privacy/partners/"
            >www.google.com/policies/privacy/partners/&nbsp;</a
          >. &nbsp;We do not respond to “do not track” browser signals at this
          time. &nbsp;We may also use web beacons, which are transparent graphic
          images on a webpage or within the body of one of our marketing emails,
          to allow us to measure visitor actions and assess the effectiveness of
          our email marketing campaigns. For example, we use web beacons in our
          email marketing to track instances where a user clicks through a link
          in the email.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>3. HOW WE USE INFORMATION</strong> <br />
        We and service providers working on our behalf use information collected
        on our Website in a variety of ways intended to provide our Website and
        Services and to operate our business, including the following:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <u>To Provide our Services</u>. We use the information that we collect
          (i) to operate, maintain, enhance and provide our Website and our
          Services, (ii) to provide other services and information that you
          request, and (iii) to provide customer support to you and other
          parties.&nbsp;
        </li>
        <li>
          <u>To Improve, Analyze, and Personalize our Website and Services</u>.
          We use the information that we collect to understand and analyze usage
          trends and preferences; to monitor and analyze the effectiveness of
          our Website and Services; to improve our Website and Services and
          develop new products, services, features, and functionality; and to
          personalize our Website and Services, such as remembering your
          information so that you will not have to re-enter it during your visit
          or the next time you use our Website, or providing customized content
          and information.&nbsp;
        </li>
        <li>
          <u>To Contact You</u>. We may use your email address or other
          information we collect to contact you for administrative purposes such
          as customer service or to send communications, including marketing or
          promotional communications, relating to our Website and Services.
          &nbsp;Generally, you have the ability to opt out of receiving
          promotional communications as described below.&nbsp;
        </li>
        <li>
          <u>Aggregate Data</u>. We aggregate data collected through our Website
          and use it for purposes such as creating and sharing reports about the
          use of our products and services, including users’ interests, usage
          patterns, and trends. &nbsp; <br />
          Advertising. We may use non-identifiable information that you provide
          or that we collect to improve or tailor the effectiveness of
          advertising on our Website and to provide advertisements and other
          content that is tailored to you.
        </li>
        <li>
          <u>Advertising</u>. We may use non-identifiable information that you
          provide or that we collect to improve or tailor the effectiveness of
          advertising on our Website and to provide advertisements and other
          content that is tailored to you.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p><strong>4. HOW WE SHARE INFORMATION</strong></p>
      <p>
        We may share, transfer, or disclose your information if you consent to
        us doing so, as well as in the following circumstances:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <u>To Service Providers</u>. We work with third party service
          providers to provide services including loan or transaction processing
          or administration; Website hosting and other similar services,
          development and maintenance; advertising and marketing services; and
          other services related to our Website and Services for us. These third
          parties may have access to or process your information as part of
          providing those services for us. Generally, we limit the information
          provided to these service providers to that which is reasonably
          necessary for them to perform their functions, and we require them to
          agree to maintain the confidentiality of such information. These
          service providers are not permitted to use information we share that
          might identify you for any purpose other than to provide services
          to&nbsp;Gold Star&nbsp;or to you.
        </li>
        <li>
          <u>In Aggregate Form</u>. We may make certain aggregated non-personal
          information available to third parties for various purposes, including
          (i) compliance with various reporting obligations; (ii) for business
          or marketing purposes; or (iii) to assist such parties in
          understanding our users’ interests, usage patterns, and borrowing and
          loan origination trends. &nbsp;
        </li>
        <li>
          <u>
            Compliance with Laws and Law Enforcement; Protection of Our Rights </u
          >. We may disclose your information (including your personal
          information) to a third party if (a) we believe that disclosure is
          reasonably necessary to comply with any applicable law, regulation,
          legal process or governmental request, (b) to enforce our agreements,
          policies and terms of service, (c) to protect the security or
          integrity of our Website or Services, (d) to protect the property,
          rights, and safety of&nbsp;Gold Star, our users or the public from
          harm or illegal activities, (e) to respond to an emergency which we
          believe in the good faith requires us to disclose information to
          assist in preventing the death or serious bodily injury of any person,
          or (f) to investigate and defend ourselves against any third-party
          claims or allegations.
        </li>
        <li>
          <u>In Significant Business Transactions</u>. Your information,
          including personal information, may be disclosed and otherwise
          transferred to an acquirer, successor, or assignee as part of any
          merger, acquisition, debt financing, sale of assets, or similar
          transaction, or in the event of an insolvency, bankruptcy, or
          receivership in which information is transferred to one or more third
          parties as one of our business assets.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>5. OUR POLICY CONCERNING CHILDREN</strong> <br />
        Our website is not directed to children under 13, and we do not
        knowingly collect any personal information from children under the age
        of 13 through our website. If we become aware that a child under 13 has
        provided us with personal information through our website, we will take
        steps to delete such information.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong
          >6. PRIVACY POLICIES OF LINKED SITES&nbsp;AND ADVERTISERS</strong
        >
        <br />
        Our Website may contain links to other sites, as well as advertisements
        from companies linking to their own sites.&nbsp;We are not responsible
        for the privacy practices or the content of such sites.&nbsp;If you have
        any questions about how these other sites use your information, you
        should contact them directly.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>7. SECURITY</strong> <br />
        Gold Star&nbsp;has certain measures in place to maintain the security,
        confidentiality and integrity of the information gathered through the
        Website, and to help protect against the loss, misuse and alteration of
        such information. &nbsp;While we take measures to protect your personal
        information against security breaches and unauthorized access, we cannot
        guarantee that our safeguards will be effective all of the time against
        all security threats.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8. CHANGES TO OUR PRIVACY POLICY</strong> <br />
        This Privacy Policy may be revised from time to time. If we decide to
        make material changes to our Privacy Policy, we will make reasonable
        efforts to notify you of the changes by sending a notice to the primary
        email address provided to us and/or by placing a notice on our
        Website.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9. HOW TO ACCESS AND UPDATE YOUR INFORMATION</strong> <br />
        You can access and update the information that you have provided to us
        as follows:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          If you are a&nbsp;Gold Star&nbsp;customer, you can access and update
          certain personal information in connection with your account or
          application by logging into your account online.&nbsp;
        </li>
        <li>
          You may contact us at&nbsp;[Company Email]&nbsp;and request to review,
          amend or delete certain personal information collected by us.
        </li>
        <li>
          Please note that we may not be able to delete all information we
          collect through our Website.
        </li>
        <li>
          You may opt-out of receiving marketing emails at any time by following
          the steps to “unsubscribe” described in the footer of our marketing
          emails. Your opt-out will become effective in 10 days or less.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>10. CONTACT US</strong> <br />
        If you have any questions, comments or concerns regarding our Privacy
        Policy and/or practices, please send an email to:&nbsp;[Company
        Email]&nbsp;
      </p>
      <p>
        <br />
        Gold Star Mortgage Financial Group, Corp
      </p>
      <p>100 Phoenix Drive</p>
      <p>Suite 300</p>
      <p>Ann Arbor, MI 48108</p>
    </div>
  </div>
</template>

