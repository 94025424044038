import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import Terms from "./components/termsOfUse.vue";
import ConsumerPrivacy from "./components/consumerPrivacy.vue";
import WebsitePrivacy from "./components/websitePrivacy.vue";
import CompanyLicenses from "./components/companyLicenses.vue";
import CCPA from "./components/CCPA.vue";
import DoNotSell from "./components/doNotSell.vue";

const app = createApp(App);
app.use(router).mount("#app");
app.component("terms-of-use", Terms);
app.component("consumer-privacy", ConsumerPrivacy);
app.component("website-privacy", WebsitePrivacy);
app.component("company-licenses", CompanyLicenses);
app.component("ccpa-privacy", CCPA);
app.component("do-not-sell", DoNotSell);
