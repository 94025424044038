<template>
  <div style="align-items: center; width: 80%; margin: auto">
    <div
      style="
        width: 100%;
        height: 4px;
        background-color: rgba(75, 74, 74, 1);
        margin-bottom: 2px;
        border-radius: 50%;
      "
    ></div>
  </div>
</template>
