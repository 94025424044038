<template>
  <div class="container">
    Last Revised: Sep 4, 2015<br /><br />
    <p><u>PRIVACY POLICY</u></p>
    <p><strong>Introduction</strong></p>
    <p>
      Gold Star Mortgage Financial Group, Corporation ("Gold Star") is committed
      to providing the tools necessary for its clients to have the financial
      flexibility they desire, including counseling them on all of their home
      financing needs. The purpose of this Policy is to outline how we treat and
      safeguard non-public personally identifiable information.
    </p>
    <strong>Definitions</strong>
    <ul>
      <li>
        <em><u>Affiliates</u></em
        >: Companies related by common ownership or control. They can be both
        financial and non-financial companies.
      </li>
    </ul>
    <ul>
      <li>
        <em><u>Confidential Information</u></em
        >: The confidential and non-public information of Gold Star and/or its
        consumers, including but not limited to Consumer Information (as defined
        below). Non-public information is any information that cannot be found
        in public sources.
      </li>
    </ul>
    <ul>
      <li>
        <em><u>Consumer</u></em
        >: A person who obtains or has obtain a financial product from Gold Star
        that is to be used primarily for personal, family, or household
        purposes.
      </li>
    </ul>
    <ul>
      <li>
        <em><u>Cookies</u></em
        >: A cookie is a small piece of information that our website may provide
        to your computer for record-keeping purposes.
      </li>
    </ul>
    <ul>
      <li>
        <em><u>Customer</u></em
        >: A subclass of "consumers" who have a continuing relationship with a
        financial institution.
      </li>
    </ul>
    <ul>
      <li>
        <em><u>Non-public Information:</u></em
        ><strong></strong>Any personally identifiable information that a
        financial institution collects about an individual in connection with
        providing a financial product or service, unless that information is
        otherwise publicly available. This includes any information that you
        provide to get a financial product or service (e.g. name, address,
        social security number, or information on an application), or any
        information about you from a transaction involving Gold Star and
        you.&nbsp;
      </li>
    </ul>
    <strong>Restrictions</strong>
    <p>
      We restrict access to your non-public information to Gold Star personnel
      who need to know that information in order to provide products or services
      to you. We maintain physical, electronic, logical, and procedural
      safeguards that comply with federal regulations in order to guard your
      nonpublic personal information.
    </p>
    <strong>Information We Collect When You Do Business With Us</strong>
    <p>
      We collect nonpublic personal information about you from the following
      sources:
    </p>
    <ul>
      <li>Information we receive from you on applications or other forms;</li>
      <li>
        Information about your transactions with us, our affiliates, or others
        (such as your loan balance, payment histories, and other account
        information);
      </li>
      <li>Information we receive from a consumer reporting agency;</li>
      <li>
        Information we receive from other mortgage settlement services providers
        when we process your transaction.
      </li>
    </ul>
    <p>
      Collecting this information allows us to assist you in meeting your
      financial goals. We are unable to process your requests without some or
      all of this information, to keep you informed of new products or services
      that might be beneficial to you, and to generally understand your needs.
      <br />
      &nbsp;
    </p>
    <strong>Information We Collect When You Browse Our Website</strong>
    <p>
      We do not access information about your identity when you browse our
      website. In order to improve user experience, we are provided general
      information about the date and time you accessed our website, the
      geographic location that you accessed it from, the type of browser that
      you used, and other non-identifying information, however that information
      is stored with third parties and you remain anonymous to Gold Star.
    </p>
    <p>
      Gold Star uses a variety of third party services to provide you with the
      best possible experience while using our website. We also use third
      parties such as Google Analytics to help us understand how to tailor our
      website to assist our visitors. These third parties may collect
      information from your browser, and may tailor advertising content so that
      you see our advertisements on other websites. In order to limit tracking
      of your browser information, you must adjust the Privacy Settings in your
      browser.
    </p>
    <p>
      Gold Star uses cookies in order to identify which pages of the site you
      access, and also to improve your online experience by allowing pages to
      load faster. Assigning a cookie to your computer does not provide Gold
      Star with access to your computer or any information contained on the
      computer, and your computer is not harmed by Gold Star's use of cookies.
      Most web browsers afford the opportunity to reject or delete cookies. To
      reject cookies, you should limit your Privacy Settings. You may also
      delete cookies in your Internet browser. Please note that if you use more
      than one type of browser or more than one computer to access the Internet,
      you may have to remove cookies in each browser and on each computer that
      you use. If you return to Gold Star, you may receive a new cookie. By
      rejecting cookies, you may be removing some of your ability to access
      content on Gold Star's website. For your convenience, we have included the
      links below to some common web browsers to assist you in setting your
      privacy limits. We do not make any representations as to the accuracy of
      the data contained at these links, and you agree that you assume all risk
      and responsibility by navigating to the links provided below. For the most
      up-to-date information, you should contact the company that provides your
      internet browser.
    </p>
    <ul style="padding-left: 0px">
      <li>
        <strong>Chrome:</strong>
        <a href="https://support.google.com/chrome/answer/114836?hl=en"
          >https://support.google.com/chrome/answer/114836?hl=en</a
        >
      </li>
      <li>
        <strong>Firefox:</strong>
        <a
          href="https://support.mozilla.org/en-US/kb/how-do-i-turn-do-not-track-feature"
          >https://support.mozilla.org/en-US/kb/how-do-i-turn-do-not-track-feature</a
        >
      </li>
      <li>
        <strong>Safari:</strong>
        <a href="http://support.apple.com/kb/PH11952"
          >http://support.apple.com/kb/PH11952</a
        >
      </li>
      <li>
        <strong>IE:</strong>
        <a
          href="http://windows.microsoft.com/en-us/internet-explorer/ie-do-not-track#ie=ie-11"
          >http://windows.microsoft.com/en-us/internet-explorer/ie-do-not-track#ie=ie-11</a
        >
      </li>
    </ul>
    <ul style="padding-left: 0px">
      <li><strong>Sharing Information</strong></li>
    </ul>
    <ul style="padding-left: 0px">
      <table>
        <tbody>
          <tr>
            <td valign="top">
              <p><strong>FACTS</strong></p>
            </td>
            <td valign="top">
              <p>
                <strong>WHAT DOES GOLD STAR DO WITH YOUR INFORMATION?</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p><strong>WHY?</strong></p>
            </td>
            <td valign="top">
              <p>
                Financial companies choose how they share your personal
                information. Federal law gives consumers the right to limit some
                but not all sharing. Federal law also requires us to tell you
                how we collect, share, and protect your personal information.
                Please read this notice carefully to understand what we
                do.<strong></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p><strong>WHAT?</strong></p>
            </td>
            <td valign="top">
              <p>
                The types of personal information we collect and share depend on
                the product or service you have with us. This information can
                include:
              </p>
              <ul>
                <li>Social Security number and credit scores</li>
                <li>Income and asset information</li>
                <li>Home valuation information</li>
                <li>Employment information</li>
                <li>
                  When you are no longer our customer, we continue to share your
                  information as described in this notice.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p><strong>HOW?</strong></p>
            </td>
            <td valign="top">
              <p>
                All financial companies need to share customers' personal
                information to run their everyday business and process your
                transactions. In the section below, we list the reasons Gold
                Star chooses to share and what you can do to limit the sharing.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <ul style="padding-left: 0px">
      <table >
        <tbody>
          <tr>
            <td valign="top">
              <p align="center">&nbsp;</p>
              <p align="center">
                <strong>Reasons We Share Personal Information</strong>
              </p>
            </td>
            <td valign="top">
              <p><strong>Does Gold Star share?</strong></p>
            </td>
            <td valign="top">
              <p><strong>Can you limit sharing?</strong></p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                For our everyday business purposes - such as to process your
                transactions, respond to audits or court orders, or to comply
                with criminal investigations.
              </p>
            </td>
            <td valign="top">
              <p align="center"><strong>&nbsp;</strong></p>
              <p align="center"><strong>YES</strong></p>
            </td>
            <td valign="top">
              <p align="center"><strong>&nbsp;</strong></p>
              <p align="center"><strong>NO</strong></p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                For our marketing purposes - to offer products and services to
                you.
              </p>
            </td>
            <td valign="top">
              <p align="center"><strong>YES</strong></p>
            </td>
            <td valign="top">
              <p align="center"><strong>NO</strong></p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>For joint marketing with other financial companies</p>
            </td>
            <td valign="top">
              <p align="center"><strong>NO</strong></p>
            </td>
            <td valign="top">
              <p align="center"><strong>NOT SHARED</strong></p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                For our affiliates everyday business purposes - information
                about transactions and experience
              </p>
            </td>
            <td valign="top">
              <p align="center"><strong>YES</strong></p>
            </td>
            <td valign="top">
              <p align="center"><strong>NO</strong></p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                For our affiliates everyday business purposes - information
                about your credit worthiness
              </p>
            </td>
            <td valign="top">
              <p align="center"><strong>YES</strong></p>
            </td>
            <td valign="top">
              <p align="center"><strong>NO</strong></p>
            </td>
          </tr>
          <tr>
            <td valign="top"><p>For our affiliates to market to you</p></td>
            <td valign="top">
              <p align="center"><strong>NO</strong></p>
            </td>
            <td valign="top">
              <p align="center"><strong>NOT SHARED</strong></p>
            </td>
          </tr>
          <tr>
            <td valign="top"><p>For non-affiliates to market to you</p></td>
            <td valign="top">
              <p align="center"><strong>NO</strong></p>
            </td>
            <td valign="top">
              <p align="center"><strong>NOT SHARED</strong></p>
            </td>
          </tr>
        </tbody>
      </table>
    </ul>
    <br />
    <p>
      For Alaska, Illinois, Maryland, and North Dakota Residents: We will not
      share your personal information with non-affiliates, either for them to
      market to you or for joint marketing - without your authorization. - For
      California Residents: We will not share personal information with
      non-affiliates either for them to market to you or for joint marketing -
      without your authorization. We will also limit our sharing of personal
      information about you with our affiliates to comply with all California
      privacy laws. - For Massachusetts, Mississippi and New Jersey Residents:
      We will not share personal information from deposit or share relationships
      with non-affiliates either for them to market to you or for joint
      marketing - without your authorization.
    </p>
    <p>Gold Star shares nonpublic personal information:</p>
    <ul>
      <li>
        To companies involved in the loan process, such as appraisers,
        processors, title companies, credit reporting companies, insurance
        companies, and mortgage investors and/or mortgage servicers. These
        companies are a necessary part of the transaction and therefore we must
        provide at least some of your information to them.
      </li>
      <li>
        With service providers who assist us in processing the above mentioned
        functions. <br />
      </li>
    </ul>
    <strong>Links to Other Websites</strong>
    <p>
      Gold Star's website may include links to other websites, whose privacy
      practices may differ from our own. Any use of those websites will be
      governed by those privacy statements, and you are encouraged to
      familiarize yourself with the privacy statement of any website you visit.
    </p>
    <strong>Limit Sharing</strong>
    <p>
      You have the opportunity to limit the sharing of information to the extent
      permitted by law. If you wish to limit sharing or opt out, you must
      request that your information by removed by writing to:
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Gold Star Mortgage Financial Group<br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      ATTN: Compliance Dept. <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      100 Phoenix Drive, Suite 300<br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Ann Arbor, MI 48108
    </p>
    <p>
      or calling: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 734-971-9900<strong
      ></strong>
    </p>
    <p>
      If you establish a new customer relationship with us after you opt out,
      your previous opt-out request will no longer apply.
    </p>
    <strong>Policy Changes</strong>
    <p>
      We may update this Policy in accordance with changes to our information
      security practices. We reserve the right to modify this Privacy Policy at
      any time without notice, but the most current version will always be
      available by clicking on the link at the bottom of the
      <a href="http://www.goldstarfinancial.com">www.goldstarfinancial.com</a>
      URL.
    </p>
    <strong>Additional Contact</strong>
    <p>
      You may contact our corporate headquarters if you have additional
      questions regarding our use of your information and the protections that
      we provide:
    </p>
    <p>
      Gold Star Mortgage Financial Group, Corporation<br />
      Attn: Compliance<br />
      100 Phoenix Drive, Suite 300<br />
      Ann Arbor, MI 48108
    </p>
    <p>Phone: 734-971-9900</p>
  </div>
</template>
