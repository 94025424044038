<template>
  <div>
    <div class="header" style="background-color: white; justify-content: left">
      <a :href="loinfo.header_link">
        <img :src="loinfo.main_logo" style="padding-left: 5px" />
      </a>
    </div>
    
    <infoPage style="margin: 0 75px;"></infoPage>

    <LOFooter :loinfo="loinfo" style="bottom: 0; padding: 0; margin: 0;"></LOFooter>
  </div>
</template>

<script>
import LOFooter from "../components/LOFooter.vue";
import apiService from "../api/api.service.js";
import infoPage from "../components/infoPage.vue";

export default {
  data: function () {
    return {
      loinfo: [],
      reviews: [],
      larFromQuery: "",
      infoPage: "",
    };
  },
  props: {
    msg: String,
  },
  components: {
    LOFooter,
    infoPage,
  },
  methods: {
    getLOInfo() {
      return apiService
        .GetLOPage(this.larFromQuery)
        .then((res) => {
          this.loinfo = res.data.data[0];

        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.larFromQuery = params.lar;
  },
  mounted() {
    this.getLOInfo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/src/assets/css/LOBody.css";

.loan-program-image {
  height: 30%;
  width: 40%;
  margin: auto;
}
.two-pane {
  padding: 20px;
}

@media (max-width: 1300px) {
  .two-pane {
    flex-direction: column;
    padding: 5px;
  }
  .loan-program-image {
    width: 100%;
    height: auto;
  }
  .two-pane-vertical {
    margin: 10px;
  }
}
@media (max-width: 425px) {
  .content-left {
    padding: 0;
  }
}
</style>
