<template>
  <div>
    <LOHeader
      @show="showContactFormDialog"
      @lock="lockScroll"
      :loinfo="loinfo"
    ></LOHeader>

    <div v-if="selectedLoanProgram?.program" style="margin-top: 120px">
      <div class="two-pane-vertical">
        <div
          class="content-left"
          style="width: 90%; border-bottom: 1px solid black; padding: 10px"
        >
          <strong>{{ selectedLoanProgram.pageHeader }}</strong>
        </div>
        <div class="two-pane">
          <img :src="selectedLoanProgram.imgUrl" class="loan-program-image" />
          <div class="content-left" style="font-size: 1.2rem">
            <strong>{{ selectedLoanProgram.bodyContent }}</strong>
            <div v-for="list in selectedLoanProgram.lists" :key="list">
              <h3>{{ list.listHeader }}</h3>
              <ul>
                <li v-for="item in list.listItems" :key="item">{{ item }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LOFooter :loinfo="loinfo" style="bottom: 0;"></LOFooter>


  </div>
</template>

<script>
import LOHeader from "../components/LOHeader.vue";
import LOFooter from "../components/LOFooter.vue";
import apiService from "../api/api.service.js";

export default {
  data: function () {
    return {
      contactFormDialog: false,
      loinfo: [],
      reviews: [],
      larFromQuery: "",
      programName: "",
      loanPrograms: [
        {
          program: "FHA",
          pageHeader: "FHA Loans",
          imgUrl:
            "https://connectgs.com/images/CorporateWebsite/LoanProducts/Products/FHA/mobile.jpg",
          bodyContent:
            "For generations, the FHA Loan Program has made the dream of homeownership a reality for those who would otherwise be unable to qualify for a home loan. FHA financing guidelines permit smaller down payment options, less than perfect credit and shorter wait periods after recovering from a bankruptcy or foreclosure. With historically low interest rates and rising home values, now is the perfect time to purchase your home. We, along with FHA, have spent decades making dreams come true.",
          lists: [
            {
              listHeader: "Borrowers May Qualify For:",
              listItems: [
                "Low Down-Payment Option",
                "580 Minimum Credit Score",
                "Gift Fund Allowed For Down Payment And/Or Closing Costs",
                "Finance Two Years After Bankruptcy Discharge - Chapter 7",
                "May Be Able To Finance During Chapter 13 Bankruptcy",
              ],
            },
          ],
        },
        {
          program: "ConventionalLoan",
          pageHeader: "Conventional Mortgage",
          imgUrl:
            "https://connectgs.com/images/CorporateWebsite/LoanProducts/Products/Conventional_97_LTV/mobile.jpg",
          bodyContent: "",
          lists: [
            {
              listHeader:
                "We are giving you the power to say YES to more clients than ever!",
              listItems: [
                "Purchase Only (Single Family Residences, PUDs, or Condos)",
                "Minimum FICO Score: 620",
                "Gift Funds Allowed",
              ],
            },
          ],
        },
        {
          program: "VALoan",
          pageHeader: "VA Loans",
          imgUrl:
            "https://connectgs.com/images/CorporateWebsite/LoanProducts/Products/VA_Home_Loans/mobile.jpg",
          bodyContent:
            "We proudly offer mortgage products available to Veterans in accordance with the Department of Veterans Affairs guidelines. These flexible products with favorable loan terms are available to qualified Veterans and active duty personnel. You served your country with honor, so let the VA loan program honor your service.",
          lists: [
            {
              listHeader: "Features and Benefits",
              listItems: [
                "Purchase, rate/term refinance, and cash-out refinance options",
                "No down payment required*",
                "Fixed- and adjustable-interest rates available",
                "Seller-assistance options",
                "Low minimum credit score requirement",
                "No monthly mortgage insurance premiums",
              ],
            },
            {
              listHeader: "Eligible Properties:",
              listItems: [
                "1- to 4-unit property",
                "PUDs",
                "Condominiums",
                "Manufactured homes",
              ],
            },
          ],
        },
        {
          program: "RefinanceLoan",
          pageHeader: "Refinance Loans",
          imgUrl:
            "https://connectgs.com/images/CorporateWebsite/LoanProducts/Products/Refinancing/mobile.jpg",
          bodyContent:
            "Do you have debts that you would like to consolidate? Would you like to eliminate your mortgage insurance and lower your payments, or do you need cash for home improvements?",
          lists: [
            {
              listHeader: "Qualiﬁed Borrowers may be eligible to:",
              listItems: [
                "Lower monthly principal and interest payments",
                "Reﬁnance without an appraisal",
                "Consolidate debts",
                "FHA Streamline",
                "Reﬁnance out of an adjustable rate mortgage",
              ],
            },
            {
              listHeader: "Refinance to:",
              listItems: [
                "15 or 30-year Fixed Mortgage",
                "VA Loan",
                "FHA Loan",
                "FHA Streamline",
                "Jumbo Loans",
              ],
            },
          ],
        },
        {
          program: "JumboFinancing",
          pageHeader: "Jumbo Financing",
          imgUrl:
            "https://connectgs.com/images/CorporateWebsite/LoanProducts/Products/JUMBO_Financing/mobile.jpg",
          bodyContent:
            "We are committed to providing exceptional service during the biggest financing decisions of your lifetime. We make certain your loan terms meet your very specific set of needs and objectives. Starting from application through closing and beyond, you’ll appreciate our simple, straightforward approach.",
          lists: [
            {
              listHeader:
                "Loans up to $2 million | Self-Employed Friendly Options",
              listItems: [],
            },
            {
              listHeader: "Financing Up to 80% LTV",
              listItems: [
                "1 Loan; No Mortgage Insurance",
                "Loans up to $2 Million",
                "680 Minimum FICO Credit Score",
              ],
            },
          ],
        },
        {
          program: "ReverseLoan",
          pageHeader: "Reverse Mortgage",
          imgUrl:
            "https://connectgs.com/images/CorporateWebsite/LoanProducts/Products/ReverseMortgage/mobile.jpg",
          bodyContent:
            "A Reverse Mortgage is a type of loan that allows Borrowers who are 62 years old and older to liquefy their home’s equity into cash. What makes a Reverse Mortgage different from other equity loans is that there will be no monthly mortgage payment required, as long as the home remains the principal residence of the Borrower.",
          lists: [
            {
              listHeader:
                "Here are some other key features of a Reverse Mortgage:",
              listItems: [
                "Monthly payments include regular maintenance of the property, real estate taxes, hazard insurance premiums and HOA dues",
                "Loan amount is calculated by an HUD formula",
                "Borrowers retain ownership of the property",
                "Loan must be repaid in one lump sum once the home is no longer considered the principal residence",
                "Eligible properties include single-family homes, condominiums and townhomes",
              ],
            },
          ],
        },
        {
          program: "CondoFinancing",
          pageHeader: "Condo Financing",
          imgUrl:
            "https://connectgs.com/images/CorporateWebsite/LoanProducts/Products/Condo_Financing/mobile.jpg",
          bodyContent: "",
          lists: [
            {
              listHeader: "",
              listItems: [
                "Max LTV 95% on Primary Residence",
                "Loan Amount up to $625k",
                "Available for Purchase or Rate & Term Refinance",
                "620 Credit Score Required for all Borrowers",
                "Free Mortgage Consultation",
              ],
            },
            {
              listHeader: "Some restrictions may apply",
              listItems: [],
            },
          ],
        },
        {
          program: "DACA",
          pageHeader: "DACA Residents",
          imgUrl:
            "https://connectgs.com/images/CorporateWebsite/LoanProducts/Products/DACA_Residents/mobile.jpg",
          bodyContent: "",
          lists: [
            {
              listHeader:
                "Achieve the Dream of Homeownership. We’ll help you purchase a home of your own!",
              listItems: [
                "Valid Employment Authorization Document (EAD I-766) must reflect Category C33",
                "Conventional Financing Only – 5% Down Payment or Greater",
                "Must have an Individual Tax Identifier Number",
                "May be Required to Document Income Continuity",
              ],
            },
          ],
        },
        {
          program: "DownPaymentAssistance",
          pageHeader: "Down Payment Assistance",
          imgUrl:
            "https://connectgs.com/images/CorporateWebsite/LoanProducts/Products/Down_Payment_Assistance/mobile.jpg",
          bodyContent: "",
          lists: [
            {
              listHeader:
                "You may be eligible for a Down Payment Assistance Grant! Homeownership may be just around the corner.",
              listItems: [
                "DPA can be used with FHA Financing",
                "No Penalty to Interest Rate",
                "**Grants are Gifts - No Repayment Required!",
                "Multiple DPA Providers and State-Specific Programs",
                "Qualify for up to 5% Assistance",
              ],
            },
          ],
        },
      ],
      selectedLoanProgram: {},
    };
  },
  name: "HomeView",
  props: {
    msg: String,
  },
  components: {
    LOHeader,
    LOFooter,
  },
  methods: {
    lockScroll() {
      if (document.body.classList.contains("locked")) {
        document.body.classList.remove("locked");
      } else {
        document.body.classList.add("locked");
      }
    },
    getLOInfo() {
      return apiService
        .GetLOPage(this.larFromQuery)
        .then((res) => {
          this.loinfo = res.data.data[0];
          document.title = this.selectedLoanProgram.pageHeader;

          this.getReviews();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReviews() {
      return apiService
        .GetReviews(this.loinfo.azure_ID)
        .then((res) => {
          this.reviews = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.larFromQuery = params.lar;
    this.programName = params.programs;

    this.selectedLoanProgram = this.loanPrograms.find(
      (r) => r.program == this.programName,
    );
  },
  mounted() {
    this.getLOInfo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/src/assets/css/LOBody.css";

.loan-program-image {
  height: 30%;
  width: 40%;
  margin: auto;
}
.two-pane {
  padding: 20px;
}

@media (max-width: 1300px) {
  .two-pane {
    flex-direction: column;
    padding: 5px;
  }
  .loan-program-image {
    width: 100%;
    height: auto;
  }
  .two-pane-vertical {
    margin: 10px;
  }
}
@media (max-width: 425px) {
  .content-left {
    padding: 0;
  }
}
</style>
